/*********************************************************************************

	Template Name: Trydo React Degital Agency Template
	Note: This is style css.

**********************************************************************************/

/**************************************************************
	
	STYLESHEET INDEXING
	|
	|
	|___ Default Styles
	|	|
	|	|___ variables
	|	|___ mixins
	|	|___ reset
	|	|___ typography
	|	|___ extend
	|	|___ animations
	|	|___ shortcode
	|	|___ spacing
	|
	|
	|___Header Styles
	|	|___ header
	|	|___ mainmenu
	|
	|
	|___Inner Styles
	|	|___ breadcrump 
	|	|___ section-title 
	|	|___ button
	|	|___ pagination
	|	|___ socialicon
	|	|___ footer
	|
	|___Elements Styles
	|	|___ portfolio 
	|	|___ service 
	|	|___ slider 
	|	|___ counterup 
	|	|___ testimonial 
	|	|___ brand 
	|	|___ team 
	|	|___ callto-action 
	|	|___ tab 
	|	|___ accordion 
	|	|___ list 
	|	|___ contact 
	|
	|___BLog Styles
	|	|___ blog 
	|	|___ blog-details 
	|
	|___Template Styles
	|	|___ about 
	|	|___ portfolio-details
	|	|___ service-details
	|	|___ preview
	|
	|
	|___ END STYLESHEET INDEXING

***************************************************************/


/**************************************
    Default Styles
***************************************/
@import 'default/variables';
@import 'default/mixins';
@import 'default/reset';
@import 'default/typography';
@import 'default/extend';
@import 'default/animations';
@import 'default/shortcode';
@import 'default/spacing';



/**************************************
    Header Styles
***************************************/

@import 'header/header';
@import 'header/mainmenu';


/**************************************
    Inner Styles
***************************************/

@import 'common/breadcrump';
@import 'common/section-title';
@import 'common/button';
@import 'common/pagination';
@import 'common/socialicon';
@import 'common/footer';



/**************************************
    Elements Styles
***************************************/

@import 'elements/service';
@import 'elements/slider';
@import 'elements/404';
@import 'elements/counterup';


/**************************************
    Loader Styles
***************************************/

@import 'loader/loader'
