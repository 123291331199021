@import "https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,600,700,800,900|Poppins:300,400,500,600,700,700i&display=swap";
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*, :after, :before {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-family: sans-serif;
  line-height: 1.15;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #212529;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-original-title], abbr[title] {
  text-decoration: underline;
  cursor: help;
  text-decoration-skip-ink: none;
  border-bottom: 0;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

code, kbd, pre, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  margin-bottom: .5rem;
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

[type="button"], [type="reset"], [type="submit"], button {
  -webkit-appearance: button;
}

[type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}

[type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

.h1, h1 {
  font-size: 2.5rem;
}

.h2, h2 {
  font-size: 2rem;
}

.h3, h3 {
  font-size: 1.75rem;
}

.h4, h4 {
  font-size: 1.5rem;
}

.h5, h5 {
  font-size: 1.25rem;
}

.h6, h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  border: 0;
  border-top: 1px solid #0000001a;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.small, small {
  font-size: .875em;
  font-weight: 400;
}

.mark, mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: 90%;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  color: #6c757d;
  font-size: .875em;
  display: block;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #6c757d;
  font-size: 90%;
}

code {
  color: #e83e8c;
  word-wrap: break-word;
  font-size: 87.5%;
}

a > code {
  color: inherit;
}

kbd {
  color: #fff;
  background-color: #212529;
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: 87.5%;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  color: #212529;
  font-size: 87.5%;
  display: block;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-md, .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px;
  }
}

.row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.col {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.row-cols-1 > * {
  max-width: 100%;
  flex: 0 0 100%;
}

.row-cols-2 > * {
  max-width: 50%;
  flex: 0 0 50%;
}

.row-cols-3 > * {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.row-cols-4 > * {
  max-width: 25%;
  flex: 0 0 25%;
}

.row-cols-5 > * {
  max-width: 20%;
  flex: 0 0 20%;
}

.row-cols-6 > * {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-auto {
  width: auto;
  max-width: 100%;
  flex: none;
}

.col-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.col-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.col-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (min-width: 576px) {
  .col-sm {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-sm-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-sm-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-sm-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-sm-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-sm-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-sm-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-sm-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-sm-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-sm-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-sm-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-sm-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-sm-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-sm-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-sm-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-md-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-md-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-md-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-md-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-md-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-md-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-md-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-md-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-md-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-md-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-md-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-md-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-lg-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-lg-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-lg-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-lg-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-lg-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-lg-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-lg-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-lg-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-lg-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-lg-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-lg-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-lg-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-xl-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-xl-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-xl-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-xl-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-xl-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-xl-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-xl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-xl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

.table {
  width: 100%;
  color: #212529;
  margin-bottom: 1rem;
}

.table td, .table th {
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  padding: .75rem;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm td, .table-sm th {
  padding: .3rem;
}

.table-bordered, .table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6;
}

.table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 2px;
}

.table-borderless tbody + tbody, .table-borderless td, .table-borderless th, .table-borderless thead th {
  border: 0;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #0000000d;
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: #00000013;
}

.table-primary, .table-primary > td, .table-primary > th {
  background-color: #b8daff;
}

.table-primary tbody + tbody, .table-primary td, .table-primary th, .table-primary thead th {
  border-color: #7abaff;
}

.table-hover .table-primary:hover, .table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary, .table-secondary > td, .table-secondary > th {
  background-color: #d6d8db;
}

.table-secondary tbody + tbody, .table-secondary td, .table-secondary th, .table-secondary thead th {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover, .table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success, .table-success > td, .table-success > th {
  background-color: #c3e6cb;
}

.table-success tbody + tbody, .table-success td, .table-success th, .table-success thead th {
  border-color: #8fd19e;
}

.table-hover .table-success:hover, .table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info, .table-info > td, .table-info > th {
  background-color: #bee5eb;
}

.table-info tbody + tbody, .table-info td, .table-info th, .table-info thead th {
  border-color: #86cfda;
}

.table-hover .table-info:hover, .table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning, .table-warning > td, .table-warning > th {
  background-color: #ffeeba;
}

.table-warning tbody + tbody, .table-warning td, .table-warning th, .table-warning thead th {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover, .table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger, .table-danger > td, .table-danger > th {
  background-color: #f5c6cb;
}

.table-danger tbody + tbody, .table-danger td, .table-danger th, .table-danger thead th {
  border-color: #ed969e;
}

.table-hover .table-danger:hover, .table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light, .table-light > td, .table-light > th {
  background-color: #fdfdfe;
}

.table-light tbody + tbody, .table-light td, .table-light th, .table-light thead th {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover, .table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark, .table-dark > td, .table-dark > th {
  background-color: #c6c8ca;
}

.table-dark tbody + tbody, .table-dark td, .table-dark th, .table-dark thead th {
  border-color: #95999c;
}

.table-hover .table-dark:hover, .table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active, .table-active > td, .table-active > th, .table-hover .table-active:hover, .table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: #00000013;
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark td, .table-dark th, .table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #ffffff0d;
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: #ffffff13;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow-x: auto;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: #0000;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 1;
  background-color: #e9ecef;
}

input[type="date"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control, input[type="time"].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #495057;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file, .form-control-range {
  width: 100%;
  display: block;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  width: 100%;
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .375rem 0;
  font-size: 1rem;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

select.form-control[multiple], select.form-control[size], textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  margin-top: .25rem;
  display: block;
}

.form-row {
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
}

.form-row > .col, .form-row > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.form-check {
  padding-left: 1.25rem;
  display: block;
  position: relative;
}

.form-check-input {
  margin-top: .3rem;
  margin-left: -1.25rem;
  position: absolute;
}

.form-check-input:disabled ~ .form-check-label, .form-check-input[disabled] ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  align-items: center;
  margin-right: .75rem;
  padding-left: 0;
  display: inline-flex;
}

.form-check-inline .form-check-input {
  margin-top: 0;
  margin-left: 0;
  margin-right: .3125rem;
  position: static;
}

.valid-feedback {
  width: 100%;
  color: #28a745;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #28a745e6;
  border-radius: .25rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.form-row > .col > .valid-tooltip, .form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip, .was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip {
  display: block;
}

.form-control.is-valid, .was-validated .form-control:valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #28a745;
  padding-right: calc(1.5em + .75rem) !important;
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  background-position: right 1.5rem center;
  padding-right: 3rem !important;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.custom-select.is-valid, .was-validated .custom-select:valid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") right 1.75rem center / calc(.75em + .375rem) calc(.75em + .375rem) no-repeat;
  border-color: #28a745;
  padding-right: calc(.75em + 2.3125rem) !important;
}

.custom-select.is-valid:focus, .was-validated .custom-select:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745;
}

.form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip, .was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745;
}

.custom-control-input.is-valid ~ .custom-control-label:before, .was-validated .custom-control-input:valid ~ .custom-control-label:before {
  border-color: #28a745;
}

.custom-control-input.is-valid:checked ~ .custom-control-label:before, .was-validated .custom-control-input:valid:checked ~ .custom-control-label:before {
  background-color: #34ce57;
  border-color: #34ce57;
}

.custom-control-input.is-valid:focus ~ .custom-control-label:before, .was-validated .custom-control-input:valid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #28a74540;
}

.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label:before, .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label:before, .custom-file-input.is-valid ~ .custom-file-label, .was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745;
}

.custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .25rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.form-row > .col > .invalid-tooltip, .form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip, .was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip {
  display: block;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem) !important;
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  background-position: right 1.5rem center;
  padding-right: 3rem !important;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.custom-select.is-invalid, .was-validated .custom-select:invalid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") right 1.75rem center / calc(.75em + .375rem) calc(.75em + .375rem) no-repeat;
  border-color: #dc3545;
  padding-right: calc(.75em + 2.3125rem) !important;
}

.custom-select.is-invalid:focus, .was-validated .custom-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip, .was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid ~ .custom-control-label, .was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545;
}

.custom-control-input.is-invalid ~ .custom-control-label:before, .was-validated .custom-control-input:invalid ~ .custom-control-label:before {
  border-color: #dc3545;
}

.custom-control-input.is-invalid:checked ~ .custom-control-label:before, .was-validated .custom-control-input:invalid:checked ~ .custom-control-label:before {
  background-color: #e4606d;
  border-color: #e4606d;
}

.custom-control-input.is-invalid:focus ~ .custom-control-label:before, .was-validated .custom-control-input:invalid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #dc354540;
}

.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label:before, .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label:before, .custom-file-input.is-invalid ~ .custom-file-label, .was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.custom-file-input.is-invalid:focus ~ .custom-file-label, .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.form-inline {
  flex-flow: wrap;
  align-items: center;
  display: flex;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-group {
    flex-flow: wrap;
    flex: none;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-control {
    width: auto;
    vertical-align: middle;
    display: inline-block;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .custom-select, .form-inline .input-group {
    width: auto;
  }

  .form-inline .form-check {
    width: auto;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .form-inline .form-check-input {
    flex-shrink: 0;
    margin-top: 0;
    margin-left: 0;
    margin-right: .25rem;
    position: relative;
  }

  .form-inline .custom-control {
    justify-content: center;
    align-items: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.btn.disabled, .btn:disabled {
  opacity: .65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary.focus, .btn-primary:focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 .2rem #268fff80;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #268fff80;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary.focus, .btn-secondary:focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 .2rem #828a9180;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #828a9180;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success.focus, .btn-success:focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 .2rem #48b46180;
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #48b46180;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info.focus, .btn-info:focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 .2rem #3ab0c380;
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #3ab0c380;
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning.focus, .btn-warning:focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 .2rem #deaa0c80;
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #deaa0c80;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger.focus, .btn-danger:focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 .2rem #e1536180;
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #e1536180;
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light.focus, .btn-light:focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark.focus, .btn-dark:focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 .2rem #52585d80;
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #52585d80;
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary.focus, .btn-outline-primary:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: #0000;
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary.focus, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: #0000;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success.focus, .btn-outline-success:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: #0000;
}

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info.focus, .btn-outline-info:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: #0000;
}

.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning.focus, .btn-outline-warning:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: #0000;
}

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger.focus, .btn-outline-danger:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: #0000;
}

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light.focus, .btn-outline-light:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: #0000;
}

.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark.focus, .btn-outline-dark:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: #0000;
}

.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-link {
  color: #007bff;
  font-weight: 400;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link.focus, .btn-link:focus {
  text-decoration: underline;
}

.btn-link.disabled, .btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-group-lg > .btn, .btn-lg {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.btn-group-sm > .btn, .btn-sm {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: .5rem;
}

input[type="button"].btn-block, input[type="reset"].btn-block, input[type="submit"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  position: relative;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.width {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropdown, .dropleft, .dropright, .dropup {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  z-index: 1000;
  float: left;
  min-width: 10rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #00000026;
  border-radius: .25rem;
  margin: .125rem 0 0;
  padding: .5rem 0;
  font-size: 1rem;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-right {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: .125rem;
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: .125rem;
  top: 0;
  left: 100%;
  right: auto;
}

.dropright .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropright .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-toggle:after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: .125rem;
  top: 0;
  left: auto;
  right: 100%;
}

.dropleft .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropleft .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropleft .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="top"] {
  bottom: auto;
  right: auto;
}

.dropdown-divider {
  height: 0;
  border-top: 1px solid #e9ecef;
  margin: .5rem 0;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  clear: both;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  padding: .25rem 1.5rem;
  font-weight: 400;
  display: block;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  background-color: #e9ecef;
  text-decoration: none;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  color: #6c757d;
  white-space: nowrap;
  margin-bottom: 0;
  padding: .5rem 1.5rem;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  color: #212529;
  padding: .25rem 1.5rem;
  display: block;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group-vertical > .btn, .btn-group > .btn {
  flex: auto;
  position: relative;
}

.btn-group-vertical > .btn:hover, .btn-group > .btn:hover, .btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn-group:not(:first-child), .btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after, .dropright .dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn-group:not(:first-child), .btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="checkbox"], .btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="radio"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control, .input-group > .form-control-plaintext {
  width: 1%;
  min-width: 0;
  flex: auto;
  margin-bottom: 0;
  position: relative;
}

.input-group > .custom-file + .custom-file, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .form-control, .input-group > .custom-select + .custom-file, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .form-control, .input-group > .form-control + .custom-file, .input-group > .form-control + .custom-select, .input-group > .form-control + .form-control, .input-group > .form-control-plaintext + .custom-file, .input-group > .form-control-plaintext + .custom-select, .input-group > .form-control-plaintext + .form-control {
  margin-left: -1px;
}

.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label, .input-group > .custom-select:focus, .input-group > .form-control:focus {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  align-items: center;
  display: flex;
}

.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label:after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label, .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label:after, .input-group:not(.has-validation) > .custom-select:not(:last-child), .input-group:not(.has-validation) > .form-control:not(:last-child), .input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label, .input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label:after, .input-group.has-validation > .custom-select:nth-last-child(n+3), .input-group.has-validation > .form-control:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-append, .input-group-prepend {
  display: flex;
}

.input-group-append .btn, .input-group-prepend .btn {
  z-index: 2;
  position: relative;
}

.input-group-append .btn:focus, .input-group-prepend .btn:focus {
  z-index: 3;
}

.input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .btn, .input-group-append .input-group-text + .input-group-text, .input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  align-items: center;
  margin-bottom: 0;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-text input[type="checkbox"], .input-group-text input[type="radio"] {
  margin-top: 0;
}

.input-group-lg > .custom-select, .input-group-lg > .form-control:not(textarea) {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .custom-select, .input-group-lg > .form-control, .input-group-lg > .input-group-append > .btn, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-prepend > .input-group-text {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.input-group-sm > .custom-select, .input-group-sm > .form-control:not(textarea) {
  height: calc(1.5em + .5rem + 2px);
}

.input-group-sm > .custom-select, .input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.input-group-lg > .custom-select, .input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn, .input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text, .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn, .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), .input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  z-index: 1;
  min-height: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
  padding-left: 1.5rem;
  display: block;
  position: relative;
}

.custom-control-inline {
  margin-right: 1rem;
  display: inline-flex;
}

.custom-control-input {
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  left: 0;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label:before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label:before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label, .custom-control-input[disabled] ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label:before, .custom-control-input[disabled] ~ .custom-control-label:before {
  background-color: #e9ecef;
}

.custom-control-label {
  vertical-align: top;
  margin-bottom: 0;
  position: relative;
}

.custom-control-label:before {
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
  display: block;
  position: absolute;
  top: .25rem;
  left: -1.5rem;
}

.custom-control-label:after {
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
  display: block;
  position: absolute;
  top: .25rem;
  left: -1.5rem;
}

.custom-checkbox .custom-control-label:before {
  border-radius: .25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:before {
  background-color: #007bff;
  border-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label:before, .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-radio .custom-control-label:before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label:before {
  width: 1.75rem;
  pointer-events: all;
  border-radius: .5rem;
  left: -2.25rem;
}

.custom-switch .custom-control-label:after {
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: .5rem;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
  top: calc(.25rem + 2px);
  left: calc(2px - 2.25rem);
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label:after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  background-color: #fff;
  transform: translateX(.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-select {
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  color: #495057;
  vertical-align: middle;
  appearance: none;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: inline-block;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  background-image: none;
  padding-right: .75rem;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + .5rem + 2px);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin-bottom: 0;
  display: inline-block;
  position: relative;
}

.custom-file-input {
  z-index: 2;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  opacity: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-file-input:disabled ~ .custom-file-label, .custom-file-input[disabled] ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label:after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]:after {
  content: attr(data-browse);
}

.custom-file-label {
  z-index: 1;
  height: calc(1.5em + .75rem + 2px);
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.custom-file-label:after {
  z-index: 3;
  height: calc(1.5em + .75rem);
  color: #495057;
  content: "Browse";
  border-left: inherit;
  background-color: #e9ecef;
  border-radius: 0 .25rem .25rem 0;
  padding: .375rem .75rem;
  line-height: 1.5;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  margin-top: 0;
  margin-left: .2rem;
  margin-right: .2rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #0000;
  border-width: .5rem;
  border-color: #0000;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  background-color: #dee2e6;
  border-radius: 1rem;
  margin-right: 15px;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label:before, .custom-file-label, .custom-select {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label:before, .custom-file-label, .custom-select {
    transition: none;
  }
}

.nav {
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: .5rem 1rem;
  display: block;
}

.nav-link:focus, .nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  background-color: #0000;
  border: 1px solid #0000;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  margin-bottom: -1px;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: .25rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item, .nav-fill > .nav-link {
  text-align: center;
  flex: auto;
}

.nav-justified .nav-item, .nav-justified > .nav-link {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
  position: relative;
}

.navbar .container, .navbar .container-fluid, .navbar .container-lg, .navbar .container-md, .navbar .container-sm, .navbar .container-xl {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-right: 1rem;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  display: inline-block;
}

.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  float: none;
  position: static;
}

.navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: inline-block;
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
}

.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-md, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-md, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row;
  justify-content: flex-start;
}

.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-lg, .navbar-expand > .container-md, .navbar-expand > .container-sm, .navbar-expand > .container-xl {
  padding-left: 0;
  padding-right: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-lg, .navbar-expand > .container-md, .navbar-expand > .container-sm, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: #000000e6;
}

.navbar-light .navbar-nav .nav-link {
  color: #00000080;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #000000b3;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: #0000004d;
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
  color: #000000e6;
}

.navbar-light .navbar-toggler {
  color: #00000080;
  border-color: #0000001a;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #00000080;
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
  color: #000000e6;
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff80;
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: #ffffffbf;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: #ffffff40;
}

.navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #ffffff80;
  border-color: #ffffff1a;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #ffffff80;
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #00000020;
  border-radius: .25rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px);
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  min-height: 1px;
  flex: auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: .75rem;
}

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  background-color: #00000008;
  border-bottom: 1px solid #00000020;
  margin-bottom: 0;
  padding: .75rem 1.25rem;
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-footer {
  background-color: #00000008;
  border-top: 1px solid #00000020;
  padding: .75rem 1.25rem;
}

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
}

.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-header-pills {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-img-overlay {
  border-radius: calc(.25rem - 1px);
  padding: 1.25rem;
  position: absolute;
  inset: 0;
}

.card-img, .card-img-bottom, .card-img-top {
  width: 100%;
  flex-shrink: 0;
}

.card-img, .card-img-top {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: wrap;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
  }

  .card-deck .card {
    flex: 1 0;
    margin-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-header, .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-footer, .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-header, .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-footer, .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: .75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
    column-gap: 1.25rem;
  }

  .card-columns .card {
    width: 100%;
    display: inline-block;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  background-color: #e9ecef;
  border-radius: .25rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: .75rem 1rem;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem;
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  color: #6c757d;
  content: "/";
  padding-right: .5rem;
}

.breadcrumb-item + .breadcrumb-item:hover:before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  border-radius: .25rem;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  margin-left: -1px;
  padding: .5rem .75rem;
  line-height: 1.25;
  display: block;
  position: relative;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  background-color: #e9ecef;
  border-color: #dee2e6;
  text-decoration: none;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.page-item:first-child .page-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  margin-left: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

.badge {
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:focus, a.badge:hover {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  border-radius: 10rem;
  padding-left: .6em;
  padding-right: .6em;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

a.badge-primary:focus, a.badge-primary:hover {
  color: #fff;
  background-color: #0062cc;
}

a.badge-primary.focus, a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff80;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:focus, a.badge-secondary:hover {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary.focus, a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:focus, a.badge-success:hover {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success.focus, a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #28a74580;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:focus, a.badge-info:hover {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info.focus, a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:focus, a.badge-warning:hover {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning.focus, a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:focus, a.badge-danger:hover {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger.focus, a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #dc354580;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:focus, a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light.focus, a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:focus, a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark.focus, a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #343a4080;
}

.jumbotron {
  background-color: #e9ecef;
  border-radius: .3rem;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.alert {
  border: 1px solid #0000;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .75rem 1.25rem;
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  z-index: 2;
  color: inherit;
  padding: .75rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  height: 1rem;
  background-color: #e9ecef;
  border-radius: .25rem;
  font-size: .75rem;
  line-height: 0;
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  flex-direction: column;
  justify-content: center;
  transition: width .6s;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  align-items: flex-start;
  display: flex;
}

.media-body {
  flex: 1;
}

.list-group {
  border-radius: .25rem;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  background-color: #f8f9fa;
  text-decoration: none;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #00000020;
  padding: .75rem 1.25rem;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  border-top-width: 1px;
  margin-top: -1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: .25rem;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: .25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  border-left-width: 1px;
  margin-left: -1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  opacity: .5;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
  opacity: .75;
}

button.close {
  background-color: #0000;
  border: 0;
  padding: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  opacity: 0;
  background-color: #ffffffd9;
  background-clip: padding-box;
  border: 1px solid #0000001a;
  border-radius: .25rem;
  flex-basis: 350px;
  font-size: .875rem;
  box-shadow: 0 .25rem .75rem #0000001a;
}

.toast:not(:last-child) {
  margin-bottom: .75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  opacity: 1;
  display: block;
}

.toast.hide {
  display: none;
}

.toast-header {
  color: #6c757d;
  background-color: #ffffffd9;
  background-clip: padding-box;
  border-bottom: 1px solid #0000000d;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
  align-items: center;
  padding: .25rem .75rem;
  display: flex;
}

.toast-body {
  padding: .75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  z-index: 1050;
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.modal-dialog {
  width: auto;
  pointer-events: none;
  margin: .5rem;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  max-height: calc(100% - 1rem);
  display: flex;
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-footer, .modal-dialog-scrollable .modal-header {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - 1rem);
  align-items: center;
  display: flex;
}

.modal-dialog-centered:before {
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
  display: block;
}

.modal-dialog-centered.modal-dialog-scrollable {
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable:before {
  content: none;
}

.modal-content {
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px);
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.modal-footer > * {
  margin: .25rem;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered:before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  z-index: 1070;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  word-wrap: break-word;
  opacity: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip.show {
  opacity: .9;
}

.tooltip .arrow {
  width: .8rem;
  height: .4rem;
  display: block;
  position: absolute;
}

.tooltip .arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-auto[x-placement^="top"], .bs-tooltip-top {
  padding: .4rem 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow, .bs-tooltip-top .arrow {
  bottom: 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow:before, .bs-tooltip-top .arrow:before {
  border-width: .4rem .4rem 0;
  border-top-color: #000;
  top: 0;
}

.bs-tooltip-auto[x-placement^="right"], .bs-tooltip-right {
  padding: 0 .4rem;
}

.bs-tooltip-auto[x-placement^="right"] .arrow, .bs-tooltip-right .arrow {
  width: .4rem;
  height: .8rem;
  left: 0;
}

.bs-tooltip-auto[x-placement^="right"] .arrow:before, .bs-tooltip-right .arrow:before {
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000;
  right: 0;
}

.bs-tooltip-auto[x-placement^="bottom"], .bs-tooltip-bottom {
  padding: .4rem 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow, .bs-tooltip-bottom .arrow {
  top: 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow:before, .bs-tooltip-bottom .arrow:before {
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
  bottom: 0;
}

.bs-tooltip-auto[x-placement^="left"], .bs-tooltip-left {
  padding: 0 .4rem;
}

.bs-tooltip-auto[x-placement^="left"] .arrow, .bs-tooltip-left .arrow {
  width: .4rem;
  height: .8rem;
  right: 0;
}

.bs-tooltip-auto[x-placement^="left"] .arrow:before, .bs-tooltip-left .arrow:before {
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000;
  left: 0;
}

.tooltip-inner {
  max-width: 200px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
  padding: .25rem .5rem;
}

.popover {
  z-index: 1060;
  max-width: 276px;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.popover .arrow {
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem;
  display: block;
  position: absolute;
}

.popover .arrow:after, .popover .arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  display: block;
  position: absolute;
}

.bs-popover-auto[x-placement^="top"], .bs-popover-top {
  margin-bottom: .5rem;
}

.bs-popover-auto[x-placement^="top"] > .arrow, .bs-popover-top > .arrow {
  bottom: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="top"] > .arrow:before, .bs-popover-top > .arrow:before {
  border-width: .5rem .5rem 0;
  border-top-color: #00000040;
  bottom: 0;
}

.bs-popover-auto[x-placement^="top"] > .arrow:after, .bs-popover-top > .arrow:after {
  border-width: .5rem .5rem 0;
  border-top-color: #fff;
  bottom: 1px;
}

.bs-popover-auto[x-placement^="right"], .bs-popover-right {
  margin-left: .5rem;
}

.bs-popover-auto[x-placement^="right"] > .arrow, .bs-popover-right > .arrow {
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
  left: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="right"] > .arrow:before, .bs-popover-right > .arrow:before {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #00000040;
  left: 0;
}

.bs-popover-auto[x-placement^="right"] > .arrow:after, .bs-popover-right > .arrow:after {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff;
  left: 1px;
}

.bs-popover-auto[x-placement^="bottom"], .bs-popover-bottom {
  margin-top: .5rem;
}

.bs-popover-auto[x-placement^="bottom"] > .arrow, .bs-popover-bottom > .arrow {
  top: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="bottom"] > .arrow:before, .bs-popover-bottom > .arrow:before {
  border-width: 0 .5rem .5rem;
  border-bottom-color: #00000040;
  top: 0;
}

.bs-popover-auto[x-placement^="bottom"] > .arrow:after, .bs-popover-bottom > .arrow:after {
  border-width: 0 .5rem .5rem;
  border-bottom-color: #fff;
  top: 1px;
}

.bs-popover-auto[x-placement^="bottom"] .popover-header:before, .bs-popover-bottom .popover-header:before {
  width: 1rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
  margin-left: -.5rem;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-auto[x-placement^="left"], .bs-popover-left {
  margin-right: .5rem;
}

.bs-popover-auto[x-placement^="left"] > .arrow, .bs-popover-left > .arrow {
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
  right: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="left"] > .arrow:before, .bs-popover-left > .arrow:before {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #00000040;
  right: 0;
}

.bs-popover-auto[x-placement^="left"] > .arrow:after, .bs-popover-left > .arrow:after {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff;
  right: 1px;
}

.popover-header {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  margin-bottom: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  color: #212529;
  padding: .5rem .75rem;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block;
}

.active.carousel-item-right, .carousel-item-next:not(.carousel-item-left) {
  transform: translateX(100%);
}

.active.carousel-item-left, .carousel-item-prev:not(.carousel-item-right) {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right, .carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-next, .carousel-control-prev {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next, .carousel-control-prev {
    transition: none;
  }
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 15;
  justify-content: center;
  margin-left: 15%;
  margin-right: 15%;
  padding-left: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators li {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  z-index: 10;
  color: #fff;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  bottom: 20px;
  left: 15%;
  right: 15%;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  width: 2rem;
  height: 2rem;
  vertical-align: -.125em;
  border: .25em solid;
  border-right-color: #0000;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
  display: inline-block;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  width: 2rem;
  height: 2rem;
  vertical-align: -.125em;
  opacity: 0;
  background-color: currentColor;
  border-radius: 50%;
  animation: .75s linear infinite spinner-grow;
  display: inline-block;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:focus, a.bg-secondary:hover, button.bg-secondary:focus, button.bg-secondary:hover {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus, a.bg-success:hover, button.bg-success:focus, button.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus, a.bg-info:hover, button.bg-info:focus, button.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus, a.bg-warning:hover, button.bg-warning:focus, button.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:focus, a.bg-danger:hover, button.bg-danger:focus, button.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus, a.bg-light:hover, button.bg-light:focus, button.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus, a.bg-dark:hover, button.bg-dark:focus, button.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: #0000 !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: .2rem !important;
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-lg {
  border-radius: .3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive:before {
  content: "";
  display: block;
}

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.8571%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .sticky-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  white-space: normal;
  position: static;
  overflow: visible;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1, .my-1 {
  margin-top: .25rem !important;
}

.mr-1, .mx-1 {
  margin-right: .25rem !important;
}

.mb-1, .my-1 {
  margin-bottom: .25rem !important;
}

.ml-1, .mx-1 {
  margin-left: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2, .my-2 {
  margin-top: .5rem !important;
}

.mr-2, .mx-2 {
  margin-right: .5rem !important;
}

.mb-2, .my-2 {
  margin-bottom: .5rem !important;
}

.ml-2, .mx-2 {
  margin-left: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3, .my-3 {
  margin-top: 1rem !important;
}

.mr-3, .mx-3 {
  margin-right: 1rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

.ml-3, .mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4, .my-4 {
  margin-top: 1.5rem !important;
}

.mr-4, .mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4, .mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5, .my-5 {
  margin-top: 3rem !important;
}

.mr-5, .mx-5 {
  margin-right: 3rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}

.ml-5, .mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1, .py-1 {
  padding-top: .25rem !important;
}

.pr-1, .px-1 {
  padding-right: .25rem !important;
}

.pb-1, .py-1 {
  padding-bottom: .25rem !important;
}

.pl-1, .px-1 {
  padding-left: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2, .py-2 {
  padding-top: .5rem !important;
}

.pr-2, .px-2 {
  padding-right: .5rem !important;
}

.pb-2, .py-2 {
  padding-bottom: .5rem !important;
}

.pl-2, .px-2 {
  padding-left: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3, .py-3 {
  padding-top: 1rem !important;
}

.pr-3, .px-3 {
  padding-right: 1rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 1rem !important;
}

.pl-3, .px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4, .py-4 {
  padding-top: 1.5rem !important;
}

.pr-4, .px-4 {
  padding-right: 1.5rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4, .px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5, .py-5 {
  padding-top: 3rem !important;
}

.pr-5, .px-5 {
  padding-right: 3rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 3rem !important;
}

.pl-5, .px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.mt-n1, .my-n1 {
  margin-top: -.25rem !important;
}

.mr-n1, .mx-n1 {
  margin-right: -.25rem !important;
}

.mb-n1, .my-n1 {
  margin-bottom: -.25rem !important;
}

.ml-n1, .mx-n1 {
  margin-left: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.mt-n2, .my-n2 {
  margin-top: -.5rem !important;
}

.mr-n2, .mx-n2 {
  margin-right: -.5rem !important;
}

.mb-n2, .my-n2 {
  margin-bottom: -.5rem !important;
}

.ml-n2, .mx-n2 {
  margin-left: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3, .my-n3 {
  margin-top: -1rem !important;
}

.mr-n3, .mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3, .my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3, .mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4, .my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4, .mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4, .my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4, .mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5, .my-n5 {
  margin-top: -3rem !important;
}

.mr-n5, .mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5, .my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5, .mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .mt-sm-1, .my-sm-1 {
    margin-top: .25rem !important;
  }

  .mr-sm-1, .mx-sm-1 {
    margin-right: .25rem !important;
  }

  .mb-sm-1, .my-sm-1 {
    margin-bottom: .25rem !important;
  }

  .ml-sm-1, .mx-sm-1 {
    margin-left: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .mt-sm-2, .my-sm-2 {
    margin-top: .5rem !important;
  }

  .mr-sm-2, .mx-sm-2 {
    margin-right: .5rem !important;
  }

  .mb-sm-2, .my-sm-2 {
    margin-bottom: .5rem !important;
  }

  .ml-sm-2, .mx-sm-2 {
    margin-left: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .pt-sm-1, .py-sm-1 {
    padding-top: .25rem !important;
  }

  .pr-sm-1, .px-sm-1 {
    padding-right: .25rem !important;
  }

  .pb-sm-1, .py-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pl-sm-1, .px-sm-1 {
    padding-left: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .pt-sm-2, .py-sm-2 {
    padding-top: .5rem !important;
  }

  .pr-sm-2, .px-sm-2 {
    padding-right: .5rem !important;
  }

  .pb-sm-2, .py-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pl-sm-2, .px-sm-2 {
    padding-left: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .mt-sm-n1, .my-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -.25rem !important;
  }

  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .mt-sm-n2, .my-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -.5rem !important;
  }

  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3, .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4, .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5, .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .mt-md-1, .my-md-1 {
    margin-top: .25rem !important;
  }

  .mr-md-1, .mx-md-1 {
    margin-right: .25rem !important;
  }

  .mb-md-1, .my-md-1 {
    margin-bottom: .25rem !important;
  }

  .ml-md-1, .mx-md-1 {
    margin-left: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .mt-md-2, .my-md-2 {
    margin-top: .5rem !important;
  }

  .mr-md-2, .mx-md-2 {
    margin-right: .5rem !important;
  }

  .mb-md-2, .my-md-2 {
    margin-bottom: .5rem !important;
  }

  .ml-md-2, .mx-md-2 {
    margin-left: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .pt-md-1, .py-md-1 {
    padding-top: .25rem !important;
  }

  .pr-md-1, .px-md-1 {
    padding-right: .25rem !important;
  }

  .pb-md-1, .py-md-1 {
    padding-bottom: .25rem !important;
  }

  .pl-md-1, .px-md-1 {
    padding-left: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .pt-md-2, .py-md-2 {
    padding-top: .5rem !important;
  }

  .pr-md-2, .px-md-2 {
    padding-right: .5rem !important;
  }

  .pb-md-2, .py-md-2 {
    padding-bottom: .5rem !important;
  }

  .pl-md-2, .px-md-2 {
    padding-left: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .mt-md-n1, .my-md-n1 {
    margin-top: -.25rem !important;
  }

  .mr-md-n1, .mx-md-n1 {
    margin-right: -.25rem !important;
  }

  .mb-md-n1, .my-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-md-n1, .mx-md-n1 {
    margin-left: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .mt-md-n2, .my-md-n2 {
    margin-top: -.5rem !important;
  }

  .mr-md-n2, .mx-md-n2 {
    margin-right: -.5rem !important;
  }

  .mb-md-n2, .my-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-md-n2, .mx-md-n2 {
    margin-left: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3, .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3, .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3, .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3, .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4, .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4, .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4, .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4, .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5, .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5, .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5, .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5, .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .mt-lg-1, .my-lg-1 {
    margin-top: .25rem !important;
  }

  .mr-lg-1, .mx-lg-1 {
    margin-right: .25rem !important;
  }

  .mb-lg-1, .my-lg-1 {
    margin-bottom: .25rem !important;
  }

  .ml-lg-1, .mx-lg-1 {
    margin-left: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .mt-lg-2, .my-lg-2 {
    margin-top: .5rem !important;
  }

  .mr-lg-2, .mx-lg-2 {
    margin-right: .5rem !important;
  }

  .mb-lg-2, .my-lg-2 {
    margin-bottom: .5rem !important;
  }

  .ml-lg-2, .mx-lg-2 {
    margin-left: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .pt-lg-1, .py-lg-1 {
    padding-top: .25rem !important;
  }

  .pr-lg-1, .px-lg-1 {
    padding-right: .25rem !important;
  }

  .pb-lg-1, .py-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pl-lg-1, .px-lg-1 {
    padding-left: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .pt-lg-2, .py-lg-2 {
    padding-top: .5rem !important;
  }

  .pr-lg-2, .px-lg-2 {
    padding-right: .5rem !important;
  }

  .pb-lg-2, .py-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pl-lg-2, .px-lg-2 {
    padding-left: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .mt-lg-n1, .my-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -.25rem !important;
  }

  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .mt-lg-n2, .my-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -.5rem !important;
  }

  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3, .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4, .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5, .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .mt-xl-1, .my-xl-1 {
    margin-top: .25rem !important;
  }

  .mr-xl-1, .mx-xl-1 {
    margin-right: .25rem !important;
  }

  .mb-xl-1, .my-xl-1 {
    margin-bottom: .25rem !important;
  }

  .ml-xl-1, .mx-xl-1 {
    margin-left: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .mt-xl-2, .my-xl-2 {
    margin-top: .5rem !important;
  }

  .mr-xl-2, .mx-xl-2 {
    margin-right: .5rem !important;
  }

  .mb-xl-2, .my-xl-2 {
    margin-bottom: .5rem !important;
  }

  .ml-xl-2, .mx-xl-2 {
    margin-left: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3, .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3, .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .pt-xl-1, .py-xl-1 {
    padding-top: .25rem !important;
  }

  .pr-xl-1, .px-xl-1 {
    padding-right: .25rem !important;
  }

  .pb-xl-1, .py-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pl-xl-1, .px-xl-1 {
    padding-left: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .pt-xl-2, .py-xl-2 {
    padding-top: .5rem !important;
  }

  .pr-xl-2, .px-xl-2 {
    padding-right: .5rem !important;
  }

  .pb-xl-2, .py-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pl-xl-2, .px-xl-2 {
    padding-left: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3, .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3, .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3, .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4, .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4, .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4, .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .mt-xl-n1, .my-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -.25rem !important;
  }

  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .mt-xl-n2, .my-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -.5rem !important;
  }

  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3, .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4, .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5, .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link:after {
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: #0000;
  position: absolute;
  inset: 0;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus, a.text-success:hover {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus, a.text-info:hover {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus, a.text-warning:hover {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus, a.text-danger:hover {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus, a.text-light:hover {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus, a.text-dark:hover {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: #00000080 !important;
}

.text-white-50 {
  color: #ffffff80 !important;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *, :after, :before {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  blockquote, pre {
    page-break-inside: avoid;
    border: 1px solid #adb5bd;
  }

  img, tr {
    page-break-inside: avoid;
  }

  h2, h3, p {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body, .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark tbody + tbody, .table-dark td, .table-dark th, .table-dark thead th {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

.animated.bounceIn, .animated.bounceOut, .animated.flipOutX, .animated.flipOutY {
  animation-duration: .75s;
}

@keyframes bounce {
  0%, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translateZ(0);
  }

  40%, 43% {
    transform: translate3d(0, -30px, 0);
  }

  40%, 43%, 70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
  }

  70% {
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.bounce {
  transform-origin: bottom;
  animation-name: bounce;
}

@keyframes flash {
  0%, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.flash {
  animation-name: flash;
}

@keyframes pulse {
  0% {
    transform: scaleX(1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scaleX(1);
  }
}

.pulse {
  animation-name: pulse;
}

@keyframes rubberBand {
  0% {
    transform: scaleX(1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scaleX(1);
  }
}

.rubberBand {
  animation-name: rubberBand;
}

@keyframes shake {
  0%, to {
    transform: translateZ(0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  animation-name: shake;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.headShake {
  animation-name: headShake;
  animation-timing-function: ease-in-out;
}

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  to {
    transform: rotate(0);
  }
}

.swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  0% {
    transform: scaleX(1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate(3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate(-3deg);
  }

  to {
    transform: scaleX(1);
  }
}

.tada {
  animation-name: tada;
}

@keyframes wobble {
  0% {
    transform: none;
  }

  15% {
    transform: translate3d(-25%, 0, 0)rotate(-5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0)rotate(3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0)rotate(-3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0)rotate(2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0)rotate(-1deg);
  }

  to {
    transform: none;
  }
}

.wobble {
  animation-name: wobble;
}

@keyframes jello {
  0%, 11.1%, to {
    transform: none;
  }

  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

.jello {
  transform-origin: center;
  animation-name: jello;
}

@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scaleX(1);
  }
}

.bounceIn {
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

.bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

.bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

.bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translateZ(0);
  }
}

.bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.bounceOut {
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes flip {
  0% {
    transform: perspective(400px)rotateY(-1turn);
  }

  0%, 40% {
    animation-timing-function: ease-out;
  }

  40% {
    transform: perspective(400px)translateZ(150px)rotateY(-190deg);
  }

  50% {
    transform: perspective(400px)translateZ(150px)rotateY(-170deg);
  }

  50%, 80% {
    animation-timing-function: ease-in;
  }

  80% {
    transform: perspective(400px)scale3d(.95, .95, .95);
  }

  to {
    animation-timing-function: ease-in;
    transform: perspective(400px);
  }
}

.animated.flip {
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  0% {
    opacity: 0;
    transform: perspective(400px)rotateX(90deg);
  }

  0%, 40% {
    animation-timing-function: ease-in;
  }

  40% {
    transform: perspective(400px)rotateX(-20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotateX(10deg);
  }

  80% {
    transform: perspective(400px)rotateX(-5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipInX {
  animation-name: flipInX;
  backface-visibility: visible !important;
}

@keyframes flipInY {
  0% {
    opacity: 0;
    transform: perspective(400px)rotateY(90deg);
  }

  0%, 40% {
    animation-timing-function: ease-in;
  }

  40% {
    transform: perspective(400px)rotateY(-20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotateY(10deg);
  }

  80% {
    transform: perspective(400px)rotateY(-5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipInY {
  animation-name: flipInY;
  backface-visibility: visible !important;
}

@keyframes flipOutX {
  0% {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotateX(-20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotateX(90deg);
  }
}

.flipOutX {
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  0% {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotateY(-15deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotateY(90deg);
  }
}

.flipOutY {
  animation-name: flipOutY;
  backface-visibility: visible !important;
}

@keyframes lightSpeedIn {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    transform: skewX(20deg);
  }

  60%, 80% {
    opacity: 1;
  }

  80% {
    transform: skewX(-5deg);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

.lightSpeedOut {
  animation-name: lightSpeedOut;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  0% {
    transform-origin: center;
    opacity: 0;
    transform: rotate(-200deg);
  }

  0%, to {
    -webkit-transform-origin: center;
  }

  to {
    transform-origin: center;
    opacity: 1;
    transform: none;
  }
}

.rotateIn {
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(-45deg);
  }

  0%, to {
    -webkit-transform-origin: 0 100%;
  }

  to {
    transform-origin: 0 100%;
    opacity: 1;
    transform: none;
  }
}

.rotateInDownLeft {
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(45deg);
  }

  0%, to {
    -webkit-transform-origin: 100% 100%;
  }

  to {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: none;
  }
}

.rotateInDownRight {
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(45deg);
  }

  0%, to {
    -webkit-transform-origin: 0 100%;
  }

  to {
    transform-origin: 0 100%;
    opacity: 1;
    transform: none;
  }
}

.rotateInUpLeft {
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(-90deg);
  }

  0%, to {
    -webkit-transform-origin: 100% 100%;
  }

  to {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: none;
  }
}

.rotateInUpRight {
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  0% {
    transform-origin: center;
    opacity: 1;
  }

  0%, to {
    -webkit-transform-origin: center;
  }

  to {
    transform-origin: center;
    opacity: 0;
    transform: rotate(200deg);
  }
}

.rotateOut {
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 1;
  }

  0%, to {
    -webkit-transform-origin: 0 100%;
  }

  to {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(45deg);
  }
}

.rotateOutDownLeft {
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
  }

  0%, to {
    -webkit-transform-origin: 100% 100%;
  }

  to {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(-45deg);
  }
}

.rotateOutDownRight {
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 1;
  }

  0%, to {
    -webkit-transform-origin: 0 100%;
  }

  to {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(-45deg);
  }
}

.rotateOutUpLeft {
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
  }

  0%, to {
    -webkit-transform-origin: 100% 100%;
  }

  to {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(90deg);
  }
}

.rotateOutUpRight {
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    transform-origin: 0 0;
  }

  0%, 20%, 60% {
    -webkit-transform-origin: 0 0;
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    transform-origin: 0 0;
    transform: rotate(80deg);
  }

  40%, 80% {
    transform-origin: 0 0;
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: rotate(60deg);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 700px, 0);
  }
}

.hinge {
  animation-name: hinge;
}

@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)rotate(-120deg);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)rotate(120deg);
  }
}

.rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

.zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

.zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

.zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  0% {
    opacity: 1;
  }

  50% {
    transform: scale3d(.3, .3, .3);
  }

  50%, to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    transform-origin: bottom;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

.zoomOutDown {
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform-origin: 0;
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

.zoomOutLeft {
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform-origin: 100%;
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

.zoomOutRight {
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    transform-origin: bottom;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

.zoomOutUp {
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  0% {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translateZ(0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  0% {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  0% {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  0% {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translateZ(0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  animation-name: slideOutUp;
}

@media only screen and (max-width: 575px) {
  .container {
    width: 450px;
  }
}

@media only screen and (max-width: 479px) {
  .container {
    width: 320px;
  }
}

* {
  box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, nav, section, summary {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden] {
  display: none;
}

html, button, input, select, textarea {
  font-family: Source Sans Pro, Helvetica, sans-serif;
}

a {
  color: #ca3c08;
  text-decoration: none;
}

a:visited {
  color: #ac0404;
}

a:focus {
  outline: thin dotted;
}

a:active, a:hover {
  color: #ea9629;
  outline: 0;
}

a:hover {
  text-decoration: underline;
}

address {
  margin: 0 0 24px;
  font-style: italic;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background: #ff0;
}

p {
  margin: 0 0 24px;
}

code, kbd, pre, samp {
  -webkit-hyphens: none;
  hyphens: none;
  font-family: monospace, serif;
  font-size: 14px;
}

pre {
  color: #666;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: #f5f5f5;
  margin: 20px 0;
  padding: 20px;
  font-family: monospace;
  font-size: 14px;
  overflow: auto;
}

blockquote, q {
  -webkit-hyphens: none;
  hyphens: none;
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

blockquote {
  margin: 24px 40px;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
}

blockquote blockquote {
  margin-right: 0;
}

blockquote cite, blockquote small {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: normal;
}

blockquote em, blockquote i {
  font-style: normal;
  font-weight: 300;
}

blockquote strong, blockquote b {
  font-weight: 400;
}

small {
  font-size: smaller;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

dl {
  margin: 0 20px;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 0 20px;
}

menu, ol, ul {
  margin: 16px 0;
  padding: 0 0 0 40px;
}

ul {
  list-style-type: square;
}

nav ul, nav ol {
  list-style: none;
}

li > ul, li > ol {
  margin: 0;
}

img {
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure, form {
  margin: 0;
}

fieldset {
  min-width: inherit;
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  white-space: normal;
  border: 0;
  padding: 0;
}

button, input, select, textarea {
  max-width: 100%;
  vertical-align: baseline;
  margin: 0;
  font-size: 100%;
}

button, input {
  line-height: normal;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  appearance: button;
  cursor: pointer;
}

button[disabled], input[disabled] {
  cursor: default;
}

input[type="checkbox"], input[type="radio"] {
  padding: 0;
}

input[type="search"] {
  appearance: textfield;
  width: 270px;
  padding-right: 2px;
}

input[type="search"]::-webkit-search-decoration {
  appearance: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  vertical-align: top;
  overflow: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border-bottom: 1px solid #ededed;
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 2;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
}

caption {
  margin: 20px 0;
  font-size: 16px;
}

th {
  text-transform: uppercase;
  font-weight: bold;
}

td {
  border-top: 1px solid #ededed;
  padding: 6px 10px 6px 0;
}

del {
  color: #333;
}

ins {
  background: #fff9c0;
  text-decoration: none;
}

hr {
  height: 1px;
  background-size: 4px 4px;
  border: 0;
  margin: 0 0 24px;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

html {
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1d1d24;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71429;
  overflow: hidden;
}

a {
  transition: all .4s ease-in-out;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, address, p, pre, blockquote, dl, dd, menu, ol, ul, table, caption, hr {
  margin: 0 0 15px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #1f1f25;
  font-family: Poppins, sans-serif;
  line-height: 1.4074;
}

h1, .h1 {
  font-size: 64px;
}

h2, .h2 {
  font-size: 54px;
}

h3, .h3 {
  font-size: 24px;
}

h4, .h4 {
  font-size: 20px;
}

h5, .h5 {
  font-size: 18px;
}

h6, .h6 {
  font-size: 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1, .h1 {
    font-size: 60px;
  }

  h2, .h2 {
    font-size: 52px;
  }

  h3, .h3 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  h1, .h1 {
    font-size: 58px;
  }

  h2, .h2 {
    font-size: 50px;
  }

  h3, .h3 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 575px) {
  h1, .h1 {
    font-size: 56px;
  }
}

h1, .h1, h2, .h2, h3, .h3 {
  font-weight: 700;
}

h4, .h4, h5, .h5 {
  font-weight: 600;
}

h6, .h6 {
  font-weight: 500;
}

a:hover, a:focus, a:active {
  outline: none;
  text-decoration: none;
}

a:visited {
  color: inherit;
}

input, button, select, textarea {
  color: #1d1d24;
  background: none;
  border: 1px solid #d4d4d4;
  transition: all .4s ease-out;
}

input:focus, input:active, button:focus, button:active, select:focus, select:active, textarea:focus, textarea:active {
  border-color: #1889b5;
  outline: none;
}

input, select, textarea {
  width: 100%;
  font-size: 14px;
}

input, select {
  height: 40px;
  padding: 0 15px;
}

.textBlack {
  color: #000;
}

.bodyColor {
  color: #535353;
}

.service.service__style--2:hover .content p, .service.service__style--2:hover .content h3.title {
  color: #fff;
}

.color-1 {
  color: #8956e2;
}

.color-2 {
  color: #11ccd3;
}

.color-3 {
  color: #f4769a;
}

.color-4 {
  color: #2f21b3;
}

.bgcolor-4 {
  background: #2f21b3;
}

.rn-pagination ul.page-list, .breadcrumb-inner ul.page-list, .color-black .mainmenunav ul.mainmenu, .color-white .mainmenunav ul.mainmenu, .mainmenunav ul.mainmenu > li > ul.submenu li .submenu, .mainmenunav ul.mainmenu > li > ul.submenu, .mainmenunav ul.mainmenu, .liststyle {
  margin: 0;
  padding: 0;
  list-style: none;
}

.standard-service .content h3 a, .service.service__style--2 .content p, .service.service__style--2 .content h3.title, .service.service__style--2 .icon, .service.service__style--2:before, .service.service__style--2, .service.service__style--1, .footer-right .footer-link ul.ft-link li a, ul.social-share li a, .rn-pagination ul.page-list li a:before, a.rn-btn, button.rn-btn, a.btn-transparent:after, a.btn-transparent, .mainmenunav ul.mainmenu > li > ul.submenu li .submenu, .mainmenunav ul.mainmenu > li > ul.submenu li a, .mainmenunav ul.mainmenu > li > ul.submenu, .mainmenunav ul.mainmenu > li > a, .header-area .header-wrapper a.rn-btn {
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.section-title-default h2.title {
  font-weight: 400;
}

.fontWeight900 {
  font-weight: 900 !important;
}

.fontWeight800 {
  font-weight: 800 !important;
}

.fontWeight700 {
  font-weight: 700 !important;
}

.fontWeight600 {
  font-weight: 600 !important;
}

.fontWeight500 {
  font-weight: 500 !important;
}

.fontWeight400 {
  font-weight: 400 !important;
}

.fontWeight300 {
  font-weight: 300 !important;
}

.service.service__style--2, .service.service__style--1 {
  position: relative;
}

.textUpper {
  text-transform: uppercase;
}

.textCap {
  text-transform: capitalize;
}

p:last-child {
  margin-bottom: 0;
}

.bg_image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.text-default-gradient {
  background: linear-gradient(to right, #f81f01 50%, #ee076e 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.theme-gradient {
  background: linear-gradient(-259deg, #0ca2dd 0, #ee076e 100%);
}

.bg-theme-gradient {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#0CA2DD", endColorstr= "#ee076e", GradientType= 1);
  background: linear-gradient(145deg, #0ca2dd 0%, #ee076e 100%);
}

.rn-pagination ul.page-list li a:before {
  background: linear-gradient(-259deg, #0ca2dd 0, #ee076e 100%);
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

.animated.bounceIn, .animated.bounceOut, .animated.flipOutX, .animated.flipOutY {
  animation-duration: .75s;
}

@keyframes bounce {
  from, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.bounce {
  transform-origin: bottom;
  animation-name: bounce;
}

@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.flash {
  animation-name: flash;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-name: pulse;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  animation-name: rubberBand;
}

@keyframes shake {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  animation-name: shake;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0);
  }
}

.swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  animation-name: tada;
}

@keyframes wobble {
  from {
    transform: none;
  }

  15% {
    transform: translate3d(-25%, 0, 0)rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0)rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0)rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0)rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0)rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: none;
  }
}

.wobble {
  animation-name: wobble;
}

@keyframes jello {
  from, 11.1%, to {
    transform: none;
  }

  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

.jello {
  transform-origin: center;
  animation-name: jello;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

.bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

.bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

.bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.bounceOut {
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes flip {
  from {
    animation-timing-function: ease-out;
    transform: perspective(400px)rotate3d(0, 1, 0, -360deg);
  }

  40% {
    animation-timing-function: ease-out;
    transform: perspective(400px)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -190deg);
  }

  50% {
    animation-timing-function: ease-in;
    transform: perspective(400px)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -170deg);
  }

  80% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(.95, .95, .95);
  }

  to {
    animation-timing-function: ease-in;
    transform: perspective(400px);
  }
}

.animated.flip {
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipInX {
  animation-name: flipInX;
  backface-visibility: visible !important;
}

@keyframes flipInY {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(0, 1, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipInY {
  animation-name: flipInY;
  backface-visibility: visible !important;
}

@keyframes flipOutX {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.flipOutX {
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, -15deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }
}

.flipOutY {
  animation-name: flipOutY;
  backface-visibility: visible !important;
}

@keyframes lightSpeedIn {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(20deg);
  }

  80% {
    opacity: 1;
    transform: skewX(-5deg);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

.lightSpeedOut {
  animation-name: lightSpeedOut;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  from {
    transform-origin: center;
    opacity: 0;
    transform: rotate3d(0, 0, 1, -200deg);
  }

  to {
    transform-origin: center;
    opacity: 1;
    transform: none;
  }
}

.rotateIn {
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  from {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }

  to {
    transform-origin: 0 100%;
    opacity: 1;
    transform: none;
  }
}

.rotateInDownLeft {
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  from {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: none;
  }
}

.rotateInDownRight {
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  from {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    transform-origin: 0 100%;
    opacity: 1;
    transform: none;
  }
}

.rotateInUpLeft {
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  from {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, -90deg);
  }

  to {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: none;
  }
}

.rotateInUpRight {
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  from {
    transform-origin: center;
    opacity: 1;
  }

  to {
    transform-origin: center;
    opacity: 0;
    transform: rotate3d(0, 0, 1, 200deg);
  }
}

.rotateOut {
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  from {
    transform-origin: 0 100%;
    opacity: 1;
  }

  to {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }
}

.rotateOutDownLeft {
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  from {
    transform-origin: 100% 100%;
    opacity: 1;
  }

  to {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.rotateOutDownRight {
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  from {
    transform-origin: 0 100%;
    opacity: 1;
  }

  to {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.rotateOutUpLeft {
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  from {
    transform-origin: 100% 100%;
    opacity: 1;
  }

  to {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, 90deg);
  }
}

.rotateOutUpRight {
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    transform-origin: 0 0;
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    transform-origin: 0 0;
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 80deg);
  }

  40%, 80% {
    transform-origin: 0 0;
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 60deg);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 700px, 0);
  }
}

.hinge {
  animation-name: hinge;
}

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)rotate3d(0, 0, 1, 120deg);
  }
}

.rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

.zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

.zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

.zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    transform-origin: bottom;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

.zoomOutDown {
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform-origin: 0;
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

.zoomOutLeft {
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform-origin: 100%;
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

.zoomOutRight {
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    transform-origin: bottom;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

.zoomOutUp {
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  animation-name: slideOutUp;
}

.animated {
  opacity: 0;
  transform: translateY(200%);
}

.animated.bmw {
  opacity: 1;
  transform: translate(0);
}

.hidden {
  display: none;
}

.fix {
  overflow: hidden;
}

.visuallyhidden {
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.poss_relative {
  position: relative;
}

.poss_absolute {
  position: absolute;
}

.lineheight--1 {
  line-height: 1 !important;
}

.lineheight--1-5 {
  line-height: 1.5 !important;
}

.lineheight--1-8 {
  line-height: 1.8 !important;
}

.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  width: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.wrapper {
  position: relative;
}

.theme-color {
  color: #1889b5;
}

.theme-gradient {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#0CA2DD", endColorstr= "#866AA5", GradientType= 1);
  background: linear-gradient(145deg, #0ca2dd 0%, #866aa5 100%);
}

.rn-text-white {
  color: #c6c9d8 !important;
}

.bg_color--1 {
  background: #fff;
}

.bg_color--2 {
  background: #1889b5;
}

.bg_color--3 {
  background: #f8f9fc;
}

.bg_color--4 {
  background: #1a1b1f;
}

.bg_color--5 {
  background: #f8f9fc;
}

.bg_color--6 {
  background: #000;
}

.bg_color--7 {
  background: #101010;
}

.bg_overlay:before {
  content: "";
  width: 100%;
  height: 100%;
  opacity: .57;
  background: #00010c;
  position: absolute;
  top: 0;
  left: 0;
}

.bg_overlay {
  position: relative;
}

.font--1 {
  font-size: 1px !important;
}

.font--2 {
  font-size: 2px !important;
}

.font--3 {
  font-size: 3px !important;
}

.font--4 {
  font-size: 4px !important;
}

.font--5 {
  font-size: 5px !important;
}

.font--6 {
  font-size: 6px !important;
}

.font--7 {
  font-size: 7px !important;
}

.font--8 {
  font-size: 8px !important;
}

.font--9 {
  font-size: 9px !important;
}

.font--10 {
  font-size: 10px !important;
}

.font--11 {
  font-size: 11px !important;
}

.font--12 {
  font-size: 12px !important;
}

.font--13 {
  font-size: 13px !important;
}

.font--14 {
  font-size: 14px !important;
}

.font--15 {
  font-size: 15px !important;
}

.font--16 {
  font-size: 16px !important;
}

.font--17 {
  font-size: 17px !important;
}

.font--18 {
  font-size: 18px !important;
}

.font--19 {
  font-size: 19px !important;
}

.font--20 {
  font-size: 20px !important;
}

.font--21 {
  font-size: 21px !important;
}

.font--22 {
  font-size: 22px !important;
}

.font--23 {
  font-size: 23px !important;
}

.font--24 {
  font-size: 24px !important;
}

.font--25 {
  font-size: 25px !important;
}

.font--26 {
  font-size: 26px !important;
}

.font--27 {
  font-size: 27px !important;
}

.font--28 {
  font-size: 28px !important;
}

.font--29 {
  font-size: 29px !important;
}

.font--30 {
  font-size: 30px !important;
}

.font--31 {
  font-size: 31px !important;
}

.font--32 {
  font-size: 32px !important;
}

.font--33 {
  font-size: 33px !important;
}

.font--34 {
  font-size: 34px !important;
}

.font--35 {
  font-size: 35px !important;
}

.font--36 {
  font-size: 36px !important;
}

.font--37 {
  font-size: 37px !important;
}

.font--38 {
  font-size: 38px !important;
}

.font--39 {
  font-size: 39px !important;
}

.font--40 {
  font-size: 40px !important;
}

.font--41 {
  font-size: 41px !important;
}

.font--42 {
  font-size: 42px !important;
}

.font--43 {
  font-size: 43px !important;
}

.font--44 {
  font-size: 44px !important;
}

.font--45 {
  font-size: 45px !important;
}

.font--46 {
  font-size: 46px !important;
}

.font--47 {
  font-size: 47px !important;
}

.font--48 {
  font-size: 48px !important;
}

.font--49 {
  font-size: 49px !important;
}

.font--50 {
  font-size: 50px !important;
}

.font--51 {
  font-size: 51px !important;
}

.font--52 {
  font-size: 52px !important;
}

.font--53 {
  font-size: 53px !important;
}

.font--54 {
  font-size: 54px !important;
}

.font--55 {
  font-size: 55px !important;
}

.font--56 {
  font-size: 56px !important;
}

.font--57 {
  font-size: 57px !important;
}

.font--58 {
  font-size: 58px !important;
}

.font--59 {
  font-size: 59px !important;
}

.font--60 {
  font-size: 60px !important;
}

.font--61 {
  font-size: 61px !important;
}

.font--62 {
  font-size: 62px !important;
}

.font--63 {
  font-size: 63px !important;
}

.font--64 {
  font-size: 64px !important;
}

.font--65 {
  font-size: 65px !important;
}

.font--66 {
  font-size: 66px !important;
}

.font--67 {
  font-size: 67px !important;
}

.font--68 {
  font-size: 68px !important;
}

.font--69 {
  font-size: 69px !important;
}

.font--70 {
  font-size: 70px !important;
}

.font--71 {
  font-size: 71px !important;
}

.font--72 {
  font-size: 72px !important;
}

.font--73 {
  font-size: 73px !important;
}

.font--74 {
  font-size: 74px !important;
}

.font--75 {
  font-size: 75px !important;
}

.font--76 {
  font-size: 76px !important;
}

.font--77 {
  font-size: 77px !important;
}

.font--78 {
  font-size: 78px !important;
}

.font--79 {
  font-size: 79px !important;
}

.font--80 {
  font-size: 80px !important;
}

p {
  font-size: 18px;
  line-height: 30px;
}

@media only screen and (max-width: 767px) {
  p {
    font-size: 16px !important;
    line-height: 28px !important;
  }
}

p:last-child {
  margin-bottom: 0;
}

.text-italic {
  font-style: italic;
}

.text-normal {
  font-style: normal;
}

.text-underline {
  font-style: underline;
}

.fullscreen {
  min-height: 100vh;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .fullscreen {
    min-height: auto;
  }
}

.fullscreen.empty-div {
  pointer-events: none;
  background: none;
}

.row--0 {
  margin-left: 0;
  margin-right: 0;
}

.row--0 > [class*="col"] {
  padding-left: 0;
  padding-right: 0;
}

.row--5 {
  margin-left: -5px;
  margin-right: -5px;
}

.row--5 > [class*="col"] {
  padding-left: 5px;
  padding-right: 5px;
}

.row--10 {
  margin-left: -10px;
  margin-right: -10px;
}

.row--10 > [class*="col"] {
  padding-left: 10px;
  padding-right: 10px;
}

.row--20 {
  margin-left: -20px;
  margin-right: -20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--20 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--20 > [class*="col"], .row--20 > [class*="col-"] {
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--20 > [class*="col"], .row--20 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--20 > [class*="col"], .row--20 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--20 > [class*="col"], .row--20 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--20 > [class*="col"], .row--20 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--35 {
  margin-left: -35px;
  margin-right: -35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--35 {
    margin-left: -25px;
    margin-right: -25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 {
    margin-left: -25px;
    margin-right: -25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--35 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--35 > [class*="col"], .row--35 > [class*="col-"] {
  padding-left: 35px;
  padding-right: 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--35 > [class*="col"], .row--35 > [class*="col-"] {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 > [class*="col"], .row--35 > [class*="col-"] {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 > [class*="col"], .row--35 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--35 > [class*="col"], .row--35 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

input:-moz-placeholder, textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

[data-overlay], [data-black-overlay], [data-white-overlay], [data-overlay] > div, [data-overlay] > *, [data-black-overlay] > div, [data-black-overlay] > *, [data-white-overlay] > div, [data-white-overlay] > * {
  z-index: 1;
  position: relative;
}

[data-overlay]:before, [data-black-overlay]:before, [data-white-overlay]:before {
  content: "";
  height: 100%;
  width: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

[data-overlay]:before {
  background-color: #1889b5;
}

[data-black-overlay]:before {
  background-color: #00010c;
}

[data-white-overlay]:before {
  background-color: #b4abab;
}

[data-overlay="1"]:before, [data-black-overlay="1"]:before, [data-white-overlay="1"]:before {
  opacity: .1;
}

[data-overlay="2"]:before, [data-black-overlay="2"]:before, [data-white-overlay="2"]:before {
  opacity: .2;
}

[data-overlay="3"]:before, [data-black-overlay="3"]:before, [data-white-overlay="3"]:before {
  opacity: .3;
}

[data-overlay="4"]:before, [data-black-overlay="4"]:before, [data-white-overlay="4"]:before {
  opacity: .4;
}

[data-overlay="5"]:before, [data-black-overlay="5"]:before, [data-white-overlay="5"]:before {
  opacity: .5;
}

[data-overlay="6"]:before, [data-black-overlay="6"]:before, [data-white-overlay="6"]:before {
  opacity: .6;
}

[data-overlay="7"]:before, [data-black-overlay="7"]:before, [data-white-overlay="7"]:before {
  opacity: .7;
}

[data-overlay="8"]:before, [data-black-overlay="8"]:before, [data-white-overlay="8"]:before {
  opacity: .8;
}

[data-overlay="9"]:before, [data-black-overlay="9"]:before, [data-white-overlay="9"]:before {
  opacity: .9;
}

[data-overlay="10"]:before, [data-black-overlay="10"]:before, [data-white-overlay="10"]:before {
  opacity: 1;
}

#scrollUp {
  width: 40px;
  height: 40px;
  color: #1f1f25;
  text-align: center;
  background-color: #1889b5;
  border: 3px solid #eee;
  border-radius: 50px;
  bottom: 60px;
  right: 20px;
  overflow: hidden;
  z-index: 9811 !important;
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    display: none !important;
  }
}

#scrollUp i {
  font-size: 22px;
  line-height: 34px;
  display: block;
}

#scrollUp:hover i {
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.form-message {
  text-align: center;
  margin-bottom: 0;
}

.form-message.error {
  color: #f80707;
  margin-top: 20px;
}

.form-message.success {
  color: #0d8d2d;
  margin-top: 20px;
}

.thumb img, .thumbnail img {
  border-radius: 6px;
}

img.react-parallax-bgimage {
  object-fit: contain;
}

.letterspacing--2 {
  letter-spacing: 2px;
}

.pra {
  font-size: 18px;
  line-height: 2em;
}

@media only screen and (max-width: 767px) {
  .pra {
    font-size: 14px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .plr--120 {
    padding: 0 60px !important;
  }
}

@media only screen and (max-width: 767px) {
  .plr--120 {
    padding: 0 30px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .plr--120 {
    padding: 0 60px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .plr--120 {
    padding: 0 60px !important;
  }
}

@media only screen and (max-width: 767px) {
  .plr--120 {
    padding: 0 30px !important;
  }
}

.pl--0 {
  padding-left: 0 !important;
}

.pr--0 {
  padding-right: 0 !important;
}

.pt--0 {
  padding-top: 0 !important;
}

.pb--0 {
  padding-bottom: 0 !important;
}

.mr--0 {
  margin-right: 0 !important;
}

.ml--0 {
  margin-left: 0 !important;
}

.mt--0 {
  margin-top: 0 !important;
}

.mb--0 {
  margin-bottom: 0 !important;
}

.pt--260 {
  padding-top: 260px;
}

.pt--250 {
  padding-top: 250px;
}

.plr--270 {
  padding: 0 270px;
}

.plr--300 {
  padding: 0 300px;
}

.plr--340 {
  padding: 0 340px;
}

.ptb--300 {
  padding: 300px 0;
}

.ptb--340 {
  padding: 340px 0;
}

.ptb--450 {
  padding: 450px 0;
}

.plr_dec--15 {
  margin: 0 -15px;
}

.mb-dec--30 {
  margin-bottom: -30px;
}

.pt--300 {
  padding-top: 300px;
}

.ptb--5 {
  padding: 5px 0;
}

.plr--5 {
  padding: 0 5px;
}

.pt--5 {
  padding-top: 5px;
}

.pb--5 {
  padding-bottom: 5px;
}

.pl--5 {
  padding-left: 5px;
}

.pr--5 {
  padding-right: 5px;
}

.mt--5 {
  margin-top: 5px !important;
}

.mb--5 {
  margin-bottom: 5px;
}

.mr--5 {
  margin-right: 5px;
}

.ml--5 {
  margin-left: 5px;
}

.ptb--10 {
  padding: 10px 0;
}

.plr--10 {
  padding: 0 10px;
}

.pt--10 {
  padding-top: 10px;
}

.pb--10 {
  padding-bottom: 10px;
}

.pl--10 {
  padding-left: 10px;
}

.pr--10 {
  padding-right: 10px;
}

.mt--10 {
  margin-top: 10px !important;
}

.mb--10 {
  margin-bottom: 10px;
}

.mr--10 {
  margin-right: 10px;
}

.ml--10 {
  margin-left: 10px;
}

.ptb--15 {
  padding: 15px 0;
}

.plr--15 {
  padding: 0 15px;
}

.pt--15 {
  padding-top: 15px;
}

.pb--15 {
  padding-bottom: 15px;
}

.pl--15 {
  padding-left: 15px;
}

.pr--15 {
  padding-right: 15px;
}

.mt--15 {
  margin-top: 15px !important;
}

.mb--15 {
  margin-bottom: 15px;
}

.mr--15 {
  margin-right: 15px;
}

.ml--15 {
  margin-left: 15px;
}

.ptb--20 {
  padding: 20px 0;
}

.plr--20 {
  padding: 0 20px;
}

.pt--20 {
  padding-top: 20px;
}

.pb--20 {
  padding-bottom: 20px;
}

.pl--20 {
  padding-left: 20px;
}

.pr--20 {
  padding-right: 20px;
}

.mt--20 {
  margin-top: 20px !important;
}

.mb--20 {
  margin-bottom: 20px;
}

.mr--20 {
  margin-right: 20px;
}

.ml--20 {
  margin-left: 20px;
}

.ptb--25 {
  padding: 25px 0;
}

.plr--25 {
  padding: 0 25px;
}

.pt--25 {
  padding-top: 25px;
}

.pb--25 {
  padding-bottom: 25px;
}

.pl--25 {
  padding-left: 25px;
}

.pr--25 {
  padding-right: 25px;
}

.mt--25 {
  margin-top: 25px !important;
}

.mb--25 {
  margin-bottom: 25px;
}

.mr--25 {
  margin-right: 25px;
}

.ml--25 {
  margin-left: 25px;
}

.ptb--30 {
  padding: 30px 0;
}

.plr--30 {
  padding: 0 30px;
}

.pt--30 {
  padding-top: 30px;
}

.pb--30 {
  padding-bottom: 30px;
}

.pl--30 {
  padding-left: 30px;
}

.pr--30 {
  padding-right: 30px;
}

.mt--30 {
  margin-top: 30px !important;
}

.mb--30 {
  margin-bottom: 30px;
}

.mr--30 {
  margin-right: 30px;
}

.ml--30 {
  margin-left: 30px;
}

.ptb--35 {
  padding: 35px 0;
}

.plr--35 {
  padding: 0 35px;
}

.pt--35 {
  padding-top: 35px;
}

.pb--35 {
  padding-bottom: 35px;
}

.pl--35 {
  padding-left: 35px;
}

.pr--35 {
  padding-right: 35px;
}

.mt--35 {
  margin-top: 35px !important;
}

.mb--35 {
  margin-bottom: 35px;
}

.mr--35 {
  margin-right: 35px;
}

.ml--35 {
  margin-left: 35px;
}

.ptb--40 {
  padding: 40px 0;
}

.plr--40 {
  padding: 0 40px;
}

.pt--40 {
  padding-top: 40px;
}

.pb--40 {
  padding-bottom: 40px;
}

.pl--40 {
  padding-left: 40px;
}

.pr--40 {
  padding-right: 40px;
}

.mt--40 {
  margin-top: 40px !important;
}

.mb--40 {
  margin-bottom: 40px;
}

.mr--40 {
  margin-right: 40px;
}

.ml--40 {
  margin-left: 40px;
}

.ptb--45 {
  padding: 45px 0;
}

.plr--45 {
  padding: 0 45px;
}

.pt--45 {
  padding-top: 45px;
}

.pb--45 {
  padding-bottom: 45px;
}

.pl--45 {
  padding-left: 45px;
}

.pr--45 {
  padding-right: 45px;
}

.mt--45 {
  margin-top: 45px !important;
}

.mb--45 {
  margin-bottom: 45px;
}

.mr--45 {
  margin-right: 45px;
}

.ml--45 {
  margin-left: 45px;
}

.ptb--50 {
  padding: 50px 0;
}

.plr--50 {
  padding: 0 50px;
}

.pt--50 {
  padding-top: 50px;
}

.pb--50 {
  padding-bottom: 50px;
}

.pl--50 {
  padding-left: 50px;
}

.pr--50 {
  padding-right: 50px;
}

.mt--50 {
  margin-top: 50px !important;
}

.mb--50 {
  margin-bottom: 50px;
}

.mr--50 {
  margin-right: 50px;
}

.ml--50 {
  margin-left: 50px;
}

.ptb--55 {
  padding: 55px 0;
}

.plr--55 {
  padding: 0 55px;
}

.pt--55 {
  padding-top: 55px;
}

.pb--55 {
  padding-bottom: 55px;
}

.pl--55 {
  padding-left: 55px;
}

.pr--55 {
  padding-right: 55px;
}

.mt--55 {
  margin-top: 55px !important;
}

.mb--55 {
  margin-bottom: 55px;
}

.mr--55 {
  margin-right: 55px;
}

.ml--55 {
  margin-left: 55px;
}

.ptb--60 {
  padding: 60px 0;
}

.plr--60 {
  padding: 0 60px;
}

.pt--60 {
  padding-top: 60px;
}

.pb--60 {
  padding-bottom: 60px;
}

.pl--60 {
  padding-left: 60px;
}

.pr--60 {
  padding-right: 60px;
}

.mt--60 {
  margin-top: 60px !important;
}

.mb--60 {
  margin-bottom: 60px;
}

.mr--60 {
  margin-right: 60px;
}

.ml--60 {
  margin-left: 60px;
}

.ptb--65 {
  padding: 65px 0;
}

.plr--65 {
  padding: 0 65px;
}

.pt--65 {
  padding-top: 65px;
}

.pb--65 {
  padding-bottom: 65px;
}

.pl--65 {
  padding-left: 65px;
}

.pr--65 {
  padding-right: 65px;
}

.mt--65 {
  margin-top: 65px !important;
}

.mb--65 {
  margin-bottom: 65px;
}

.mr--65 {
  margin-right: 65px;
}

.ml--65 {
  margin-left: 65px;
}

.ptb--70 {
  padding: 70px 0;
}

.plr--70 {
  padding: 0 70px;
}

.pt--70 {
  padding-top: 70px;
}

.pb--70 {
  padding-bottom: 70px;
}

.pl--70 {
  padding-left: 70px;
}

.pr--70 {
  padding-right: 70px;
}

.mt--70 {
  margin-top: 70px !important;
}

.mb--70 {
  margin-bottom: 70px;
}

.mr--70 {
  margin-right: 70px;
}

.ml--70 {
  margin-left: 70px;
}

.ptb--75 {
  padding: 75px 0;
}

.plr--75 {
  padding: 0 75px;
}

.pt--75 {
  padding-top: 75px;
}

.pb--75 {
  padding-bottom: 75px;
}

.pl--75 {
  padding-left: 75px;
}

.pr--75 {
  padding-right: 75px;
}

.mt--75 {
  margin-top: 75px !important;
}

.mb--75 {
  margin-bottom: 75px;
}

.mr--75 {
  margin-right: 75px;
}

.ml--75 {
  margin-left: 75px;
}

.ptb--80 {
  padding: 80px 0;
}

.plr--80 {
  padding: 0 80px;
}

.pt--80 {
  padding-top: 80px;
}

.pb--80 {
  padding-bottom: 80px;
}

.pl--80 {
  padding-left: 80px;
}

.pr--80 {
  padding-right: 80px;
}

.mt--80 {
  margin-top: 80px !important;
}

.mb--80 {
  margin-bottom: 80px;
}

.mr--80 {
  margin-right: 80px;
}

.ml--80 {
  margin-left: 80px;
}

.ptb--85 {
  padding: 85px 0;
}

.plr--85 {
  padding: 0 85px;
}

.pt--85 {
  padding-top: 85px;
}

.pb--85 {
  padding-bottom: 85px;
}

.pl--85 {
  padding-left: 85px;
}

.pr--85 {
  padding-right: 85px;
}

.mt--85 {
  margin-top: 85px !important;
}

.mb--85 {
  margin-bottom: 85px;
}

.mr--85 {
  margin-right: 85px;
}

.ml--85 {
  margin-left: 85px;
}

.ptb--90 {
  padding: 90px 0;
}

.plr--90 {
  padding: 0 90px;
}

.pt--90 {
  padding-top: 90px;
}

.pb--90 {
  padding-bottom: 90px;
}

.pl--90 {
  padding-left: 90px;
}

.pr--90 {
  padding-right: 90px;
}

.mt--90 {
  margin-top: 90px !important;
}

.mb--90 {
  margin-bottom: 90px;
}

.mr--90 {
  margin-right: 90px;
}

.ml--90 {
  margin-left: 90px;
}

.ptb--95 {
  padding: 95px 0;
}

.plr--95 {
  padding: 0 95px;
}

.pt--95 {
  padding-top: 95px;
}

.pb--95 {
  padding-bottom: 95px;
}

.pl--95 {
  padding-left: 95px;
}

.pr--95 {
  padding-right: 95px;
}

.mt--95 {
  margin-top: 95px !important;
}

.mb--95 {
  margin-bottom: 95px;
}

.mr--95 {
  margin-right: 95px;
}

.ml--95 {
  margin-left: 95px;
}

.ptb--100 {
  padding: 100px 0;
}

.plr--100 {
  padding: 0 100px;
}

.pt--100 {
  padding-top: 100px;
}

.pb--100 {
  padding-bottom: 100px;
}

.pl--100 {
  padding-left: 100px;
}

.pr--100 {
  padding-right: 100px;
}

.mt--100 {
  margin-top: 100px !important;
}

.mb--100 {
  margin-bottom: 100px;
}

.mr--100 {
  margin-right: 100px;
}

.ml--100 {
  margin-left: 100px;
}

.ptb--105 {
  padding: 105px 0;
}

.plr--105 {
  padding: 0 105px;
}

.pt--105 {
  padding-top: 105px;
}

.pb--105 {
  padding-bottom: 105px;
}

.pl--105 {
  padding-left: 105px;
}

.pr--105 {
  padding-right: 105px;
}

.mt--105 {
  margin-top: 105px !important;
}

.mb--105 {
  margin-bottom: 105px;
}

.mr--105 {
  margin-right: 105px;
}

.ml--105 {
  margin-left: 105px;
}

.ptb--110 {
  padding: 110px 0;
}

.plr--110 {
  padding: 0 110px;
}

.pt--110 {
  padding-top: 110px;
}

.pb--110 {
  padding-bottom: 110px;
}

.pl--110 {
  padding-left: 110px;
}

.pr--110 {
  padding-right: 110px;
}

.mt--110 {
  margin-top: 110px !important;
}

.mb--110 {
  margin-bottom: 110px;
}

.mr--110 {
  margin-right: 110px;
}

.ml--110 {
  margin-left: 110px;
}

.ptb--115 {
  padding: 115px 0;
}

.plr--115 {
  padding: 0 115px;
}

.pt--115 {
  padding-top: 115px;
}

.pb--115 {
  padding-bottom: 115px;
}

.pl--115 {
  padding-left: 115px;
}

.pr--115 {
  padding-right: 115px;
}

.mt--115 {
  margin-top: 115px !important;
}

.mb--115 {
  margin-bottom: 115px;
}

.mr--115 {
  margin-right: 115px;
}

.ml--115 {
  margin-left: 115px;
}

.ptb--120 {
  padding: 120px 0;
}

.plr--120 {
  padding: 0 120px;
}

.pt--120 {
  padding-top: 120px;
}

.pb--120 {
  padding-bottom: 120px;
}

.pl--120 {
  padding-left: 120px;
}

.pr--120 {
  padding-right: 120px;
}

.mt--120 {
  margin-top: 120px !important;
}

.mb--120 {
  margin-bottom: 120px;
}

.mr--120 {
  margin-right: 120px;
}

.ml--120 {
  margin-left: 120px;
}

.ptb--125 {
  padding: 125px 0;
}

.plr--125 {
  padding: 0 125px;
}

.pt--125 {
  padding-top: 125px;
}

.pb--125 {
  padding-bottom: 125px;
}

.pl--125 {
  padding-left: 125px;
}

.pr--125 {
  padding-right: 125px;
}

.mt--125 {
  margin-top: 125px !important;
}

.mb--125 {
  margin-bottom: 125px;
}

.mr--125 {
  margin-right: 125px;
}

.ml--125 {
  margin-left: 125px;
}

.ptb--130 {
  padding: 130px 0;
}

.plr--130 {
  padding: 0 130px;
}

.pt--130 {
  padding-top: 130px;
}

.pb--130 {
  padding-bottom: 130px;
}

.pl--130 {
  padding-left: 130px;
}

.pr--130 {
  padding-right: 130px;
}

.mt--130 {
  margin-top: 130px !important;
}

.mb--130 {
  margin-bottom: 130px;
}

.mr--130 {
  margin-right: 130px;
}

.ml--130 {
  margin-left: 130px;
}

.ptb--135 {
  padding: 135px 0;
}

.plr--135 {
  padding: 0 135px;
}

.pt--135 {
  padding-top: 135px;
}

.pb--135 {
  padding-bottom: 135px;
}

.pl--135 {
  padding-left: 135px;
}

.pr--135 {
  padding-right: 135px;
}

.mt--135 {
  margin-top: 135px !important;
}

.mb--135 {
  margin-bottom: 135px;
}

.mr--135 {
  margin-right: 135px;
}

.ml--135 {
  margin-left: 135px;
}

.ptb--140 {
  padding: 140px 0;
}

.plr--140 {
  padding: 0 140px;
}

.pt--140 {
  padding-top: 140px;
}

.pb--140 {
  padding-bottom: 140px;
}

.pl--140 {
  padding-left: 140px;
}

.pr--140 {
  padding-right: 140px;
}

.mt--140 {
  margin-top: 140px !important;
}

.mb--140 {
  margin-bottom: 140px;
}

.mr--140 {
  margin-right: 140px;
}

.ml--140 {
  margin-left: 140px;
}

.ptb--145 {
  padding: 145px 0;
}

.plr--145 {
  padding: 0 145px;
}

.pt--145 {
  padding-top: 145px;
}

.pb--145 {
  padding-bottom: 145px;
}

.pl--145 {
  padding-left: 145px;
}

.pr--145 {
  padding-right: 145px;
}

.mt--145 {
  margin-top: 145px !important;
}

.mb--145 {
  margin-bottom: 145px;
}

.mr--145 {
  margin-right: 145px;
}

.ml--145 {
  margin-left: 145px;
}

.ptb--150 {
  padding: 150px 0;
}

.plr--150 {
  padding: 0 150px;
}

.pt--150 {
  padding-top: 150px;
}

.pb--150 {
  padding-bottom: 150px;
}

.pl--150 {
  padding-left: 150px;
}

.pr--150 {
  padding-right: 150px;
}

.mt--150 {
  margin-top: 150px !important;
}

.mb--150 {
  margin-bottom: 150px;
}

.mr--150 {
  margin-right: 150px;
}

.ml--150 {
  margin-left: 150px;
}

.ptb--155 {
  padding: 155px 0;
}

.plr--155 {
  padding: 0 155px;
}

.pt--155 {
  padding-top: 155px;
}

.pb--155 {
  padding-bottom: 155px;
}

.pl--155 {
  padding-left: 155px;
}

.pr--155 {
  padding-right: 155px;
}

.mt--155 {
  margin-top: 155px !important;
}

.mb--155 {
  margin-bottom: 155px;
}

.mr--155 {
  margin-right: 155px;
}

.ml--155 {
  margin-left: 155px;
}

.ptb--160 {
  padding: 160px 0;
}

.plr--160 {
  padding: 0 160px;
}

.pt--160 {
  padding-top: 160px;
}

.pb--160 {
  padding-bottom: 160px;
}

.pl--160 {
  padding-left: 160px;
}

.pr--160 {
  padding-right: 160px;
}

.mt--160 {
  margin-top: 160px !important;
}

.mb--160 {
  margin-bottom: 160px;
}

.mr--160 {
  margin-right: 160px;
}

.ml--160 {
  margin-left: 160px;
}

.ptb--165 {
  padding: 165px 0;
}

.plr--165 {
  padding: 0 165px;
}

.pt--165 {
  padding-top: 165px;
}

.pb--165 {
  padding-bottom: 165px;
}

.pl--165 {
  padding-left: 165px;
}

.pr--165 {
  padding-right: 165px;
}

.mt--165 {
  margin-top: 165px !important;
}

.mb--165 {
  margin-bottom: 165px;
}

.mr--165 {
  margin-right: 165px;
}

.ml--165 {
  margin-left: 165px;
}

.ptb--170 {
  padding: 170px 0;
}

.plr--170 {
  padding: 0 170px;
}

.pt--170 {
  padding-top: 170px;
}

.pb--170 {
  padding-bottom: 170px;
}

.pl--170 {
  padding-left: 170px;
}

.pr--170 {
  padding-right: 170px;
}

.mt--170 {
  margin-top: 170px !important;
}

.mb--170 {
  margin-bottom: 170px;
}

.mr--170 {
  margin-right: 170px;
}

.ml--170 {
  margin-left: 170px;
}

.ptb--175 {
  padding: 175px 0;
}

.plr--175 {
  padding: 0 175px;
}

.pt--175 {
  padding-top: 175px;
}

.pb--175 {
  padding-bottom: 175px;
}

.pl--175 {
  padding-left: 175px;
}

.pr--175 {
  padding-right: 175px;
}

.mt--175 {
  margin-top: 175px !important;
}

.mb--175 {
  margin-bottom: 175px;
}

.mr--175 {
  margin-right: 175px;
}

.ml--175 {
  margin-left: 175px;
}

.ptb--180 {
  padding: 180px 0;
}

.plr--180 {
  padding: 0 180px;
}

.pt--180 {
  padding-top: 180px;
}

.pb--180 {
  padding-bottom: 180px;
}

.pl--180 {
  padding-left: 180px;
}

.pr--180 {
  padding-right: 180px;
}

.mt--180 {
  margin-top: 180px !important;
}

.mb--180 {
  margin-bottom: 180px;
}

.mr--180 {
  margin-right: 180px;
}

.ml--180 {
  margin-left: 180px;
}

.ptb--185 {
  padding: 185px 0;
}

.plr--185 {
  padding: 0 185px;
}

.pt--185 {
  padding-top: 185px;
}

.pb--185 {
  padding-bottom: 185px;
}

.pl--185 {
  padding-left: 185px;
}

.pr--185 {
  padding-right: 185px;
}

.mt--185 {
  margin-top: 185px !important;
}

.mb--185 {
  margin-bottom: 185px;
}

.mr--185 {
  margin-right: 185px;
}

.ml--185 {
  margin-left: 185px;
}

.ptb--190 {
  padding: 190px 0;
}

.plr--190 {
  padding: 0 190px;
}

.pt--190 {
  padding-top: 190px;
}

.pb--190 {
  padding-bottom: 190px;
}

.pl--190 {
  padding-left: 190px;
}

.pr--190 {
  padding-right: 190px;
}

.mt--190 {
  margin-top: 190px !important;
}

.mb--190 {
  margin-bottom: 190px;
}

.mr--190 {
  margin-right: 190px;
}

.ml--190 {
  margin-left: 190px;
}

.ptb--195 {
  padding: 195px 0;
}

.plr--195 {
  padding: 0 195px;
}

.pt--195 {
  padding-top: 195px;
}

.pb--195 {
  padding-bottom: 195px;
}

.pl--195 {
  padding-left: 195px;
}

.pr--195 {
  padding-right: 195px;
}

.mt--195 {
  margin-top: 195px !important;
}

.mb--195 {
  margin-bottom: 195px;
}

.mr--195 {
  margin-right: 195px;
}

.ml--195 {
  margin-left: 195px;
}

.ptb--200 {
  padding: 200px 0;
}

.plr--200 {
  padding: 0 200px;
}

.pt--200 {
  padding-top: 200px;
}

.pb--200 {
  padding-bottom: 200px;
}

.pl--200 {
  padding-left: 200px;
}

.pr--200 {
  padding-right: 200px;
}

.mt--200 {
  margin-top: 200px !important;
}

.mb--200 {
  margin-bottom: 200px;
}

.mr--200 {
  margin-right: 200px;
}

.ml--200 {
  margin-left: 200px;
}

@media only screen and (max-width: 767px) {
  .ptb--120, .ptb--80 {
    padding: 60px 0 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb--120, .ptb--80 {
    padding: 80px 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .pt--120, .pt--140, .pt--110 {
    padding-top: 60px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt--120, .pt--140, .pt--110 {
    padding-top: 80px !important;
  }
}

@media only screen and (max-width: 767px) {
  .pb--120, .pb--110, .pb--110 {
    padding-bottom: 60px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb--120, .pb--110, .pb--110 {
    padding-bottom: 80px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .ptb_lp--5 {
    padding: 5px 0;
  }

  .plr_lp--5 {
    padding: 0 5px;
  }

  .pt_lp--5 {
    padding-top: 5px;
  }

  .pb_lp--5 {
    padding-bottom: 5px;
  }

  .pl_lp--5 {
    padding-left: 5px;
  }

  .pr_lp--5 {
    padding-right: 5px;
  }

  .mt_lp--5 {
    margin-top: 5px;
  }

  .mb_lp--5 {
    margin-bottom: 5px;
  }

  .ptb_lp--10 {
    padding: 10px 0;
  }

  .plr_lp--10 {
    padding: 0 10px;
  }

  .pt_lp--10 {
    padding-top: 10px;
  }

  .pb_lp--10 {
    padding-bottom: 10px;
  }

  .pl_lp--10 {
    padding-left: 10px;
  }

  .pr_lp--10 {
    padding-right: 10px;
  }

  .mt_lp--10 {
    margin-top: 10px;
  }

  .mb_lp--10 {
    margin-bottom: 10px;
  }

  .ptb_lp--15 {
    padding: 15px 0;
  }

  .plr_lp--15 {
    padding: 0 15px;
  }

  .pt_lp--15 {
    padding-top: 15px;
  }

  .pb_lp--15 {
    padding-bottom: 15px;
  }

  .pl_lp--15 {
    padding-left: 15px;
  }

  .pr_lp--15 {
    padding-right: 15px;
  }

  .mt_lp--15 {
    margin-top: 15px;
  }

  .mb_lp--15 {
    margin-bottom: 15px;
  }

  .ptb_lp--20 {
    padding: 20px 0;
  }

  .plr_lp--20 {
    padding: 0 20px;
  }

  .pt_lp--20 {
    padding-top: 20px;
  }

  .pb_lp--20 {
    padding-bottom: 20px;
  }

  .pl_lp--20 {
    padding-left: 20px;
  }

  .pr_lp--20 {
    padding-right: 20px;
  }

  .mt_lp--20 {
    margin-top: 20px;
  }

  .mb_lp--20 {
    margin-bottom: 20px;
  }

  .ptb_lp--25 {
    padding: 25px 0;
  }

  .plr_lp--25 {
    padding: 0 25px;
  }

  .pt_lp--25 {
    padding-top: 25px;
  }

  .pb_lp--25 {
    padding-bottom: 25px;
  }

  .pl_lp--25 {
    padding-left: 25px;
  }

  .pr_lp--25 {
    padding-right: 25px;
  }

  .mt_lp--25 {
    margin-top: 25px;
  }

  .mb_lp--25 {
    margin-bottom: 25px;
  }

  .ptb_lp--30 {
    padding: 30px 0;
  }

  .plr_lp--30 {
    padding: 0 30px;
  }

  .pt_lp--30 {
    padding-top: 30px;
  }

  .pb_lp--30 {
    padding-bottom: 30px;
  }

  .pl_lp--30 {
    padding-left: 30px;
  }

  .pr_lp--30 {
    padding-right: 30px;
  }

  .mt_lp--30 {
    margin-top: 30px;
  }

  .mb_lp--30 {
    margin-bottom: 30px;
  }

  .ptb_lp--35 {
    padding: 35px 0;
  }

  .plr_lp--35 {
    padding: 0 35px;
  }

  .pt_lp--35 {
    padding-top: 35px;
  }

  .pb_lp--35 {
    padding-bottom: 35px;
  }

  .pl_lp--35 {
    padding-left: 35px;
  }

  .pr_lp--35 {
    padding-right: 35px;
  }

  .mt_lp--35 {
    margin-top: 35px;
  }

  .mb_lp--35 {
    margin-bottom: 35px;
  }

  .ptb_lp--40 {
    padding: 40px 0;
  }

  .plr_lp--40 {
    padding: 0 40px;
  }

  .pt_lp--40 {
    padding-top: 40px;
  }

  .pb_lp--40 {
    padding-bottom: 40px;
  }

  .pl_lp--40 {
    padding-left: 40px;
  }

  .pr_lp--40 {
    padding-right: 40px;
  }

  .mt_lp--40 {
    margin-top: 40px;
  }

  .mb_lp--40 {
    margin-bottom: 40px;
  }

  .ptb_lp--45 {
    padding: 45px 0;
  }

  .plr_lp--45 {
    padding: 0 45px;
  }

  .pt_lp--45 {
    padding-top: 45px;
  }

  .pb_lp--45 {
    padding-bottom: 45px;
  }

  .pl_lp--45 {
    padding-left: 45px;
  }

  .pr_lp--45 {
    padding-right: 45px;
  }

  .mt_lp--45 {
    margin-top: 45px;
  }

  .mb_lp--45 {
    margin-bottom: 45px;
  }

  .ptb_lp--50 {
    padding: 50px 0;
  }

  .plr_lp--50 {
    padding: 0 50px;
  }

  .pt_lp--50 {
    padding-top: 50px;
  }

  .pb_lp--50 {
    padding-bottom: 50px;
  }

  .pl_lp--50 {
    padding-left: 50px;
  }

  .pr_lp--50 {
    padding-right: 50px;
  }

  .mt_lp--50 {
    margin-top: 50px;
  }

  .mb_lp--50 {
    margin-bottom: 50px;
  }

  .ptb_lp--55 {
    padding: 55px 0;
  }

  .plr_lp--55 {
    padding: 0 55px;
  }

  .pt_lp--55 {
    padding-top: 55px;
  }

  .pb_lp--55 {
    padding-bottom: 55px;
  }

  .pl_lp--55 {
    padding-left: 55px;
  }

  .pr_lp--55 {
    padding-right: 55px;
  }

  .mt_lp--55 {
    margin-top: 55px;
  }

  .mb_lp--55 {
    margin-bottom: 55px;
  }

  .ptb_lp--60 {
    padding: 60px 0;
  }

  .plr_lp--60 {
    padding: 0 60px;
  }

  .pt_lp--60 {
    padding-top: 60px;
  }

  .pb_lp--60 {
    padding-bottom: 60px;
  }

  .pl_lp--60 {
    padding-left: 60px;
  }

  .pr_lp--60 {
    padding-right: 60px;
  }

  .mt_lp--60 {
    margin-top: 60px;
  }

  .mb_lp--60 {
    margin-bottom: 60px;
  }

  .ptb_lp--65 {
    padding: 65px 0;
  }

  .plr_lp--65 {
    padding: 0 65px;
  }

  .pt_lp--65 {
    padding-top: 65px;
  }

  .pb_lp--65 {
    padding-bottom: 65px;
  }

  .pl_lp--65 {
    padding-left: 65px;
  }

  .pr_lp--65 {
    padding-right: 65px;
  }

  .mt_lp--65 {
    margin-top: 65px;
  }

  .mb_lp--65 {
    margin-bottom: 65px;
  }

  .ptb_lp--70 {
    padding: 70px 0;
  }

  .plr_lp--70 {
    padding: 0 70px;
  }

  .pt_lp--70 {
    padding-top: 70px;
  }

  .pb_lp--70 {
    padding-bottom: 70px;
  }

  .pl_lp--70 {
    padding-left: 70px;
  }

  .pr_lp--70 {
    padding-right: 70px;
  }

  .mt_lp--70 {
    margin-top: 70px;
  }

  .mb_lp--70 {
    margin-bottom: 70px;
  }

  .ptb_lp--75 {
    padding: 75px 0;
  }

  .plr_lp--75 {
    padding: 0 75px;
  }

  .pt_lp--75 {
    padding-top: 75px;
  }

  .pb_lp--75 {
    padding-bottom: 75px;
  }

  .pl_lp--75 {
    padding-left: 75px;
  }

  .pr_lp--75 {
    padding-right: 75px;
  }

  .mt_lp--75 {
    margin-top: 75px;
  }

  .mb_lp--75 {
    margin-bottom: 75px;
  }

  .ptb_lp--80 {
    padding: 80px 0;
  }

  .plr_lp--80 {
    padding: 0 80px;
  }

  .pt_lp--80 {
    padding-top: 80px;
  }

  .pb_lp--80 {
    padding-bottom: 80px;
  }

  .pl_lp--80 {
    padding-left: 80px;
  }

  .pr_lp--80 {
    padding-right: 80px;
  }

  .mt_lp--80 {
    margin-top: 80px;
  }

  .mb_lp--80 {
    margin-bottom: 80px;
  }

  .ptb_lp--85 {
    padding: 85px 0;
  }

  .plr_lp--85 {
    padding: 0 85px;
  }

  .pt_lp--85 {
    padding-top: 85px;
  }

  .pb_lp--85 {
    padding-bottom: 85px;
  }

  .pl_lp--85 {
    padding-left: 85px;
  }

  .pr_lp--85 {
    padding-right: 85px;
  }

  .mt_lp--85 {
    margin-top: 85px;
  }

  .mb_lp--85 {
    margin-bottom: 85px;
  }

  .ptb_lp--90 {
    padding: 90px 0;
  }

  .plr_lp--90 {
    padding: 0 90px;
  }

  .pt_lp--90 {
    padding-top: 90px;
  }

  .pb_lp--90 {
    padding-bottom: 90px;
  }

  .pl_lp--90 {
    padding-left: 90px;
  }

  .pr_lp--90 {
    padding-right: 90px;
  }

  .mt_lp--90 {
    margin-top: 90px;
  }

  .mb_lp--90 {
    margin-bottom: 90px;
  }

  .ptb_lp--95 {
    padding: 95px 0;
  }

  .plr_lp--95 {
    padding: 0 95px;
  }

  .pt_lp--95 {
    padding-top: 95px;
  }

  .pb_lp--95 {
    padding-bottom: 95px;
  }

  .pl_lp--95 {
    padding-left: 95px;
  }

  .pr_lp--95 {
    padding-right: 95px;
  }

  .mt_lp--95 {
    margin-top: 95px;
  }

  .mb_lp--95 {
    margin-bottom: 95px;
  }

  .ptb_lp--100 {
    padding: 100px 0;
  }

  .plr_lp--100 {
    padding: 0 100px;
  }

  .pt_lp--100 {
    padding-top: 100px;
  }

  .pb_lp--100 {
    padding-bottom: 100px;
  }

  .pl_lp--100 {
    padding-left: 100px;
  }

  .pr_lp--100 {
    padding-right: 100px;
  }

  .mt_lp--100 {
    margin-top: 100px;
  }

  .mb_lp--100 {
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ptb_lg--5 {
    padding: 5px 0;
  }

  .plr_lg--5 {
    padding: 0 5px;
  }

  .pt_lg--5 {
    padding-top: 5px;
  }

  .pb_lg--5 {
    padding-bottom: 5px;
  }

  .pl_lg--5 {
    padding-left: 5px;
  }

  .pr_lg--5 {
    padding-right: 5px;
  }

  .mt_lg--5 {
    margin-top: 5px;
  }

  .mb_lg--5 {
    margin-bottom: 5px;
  }

  .ptb_lg--10 {
    padding: 10px 0;
  }

  .plr_lg--10 {
    padding: 0 10px;
  }

  .pt_lg--10 {
    padding-top: 10px;
  }

  .pb_lg--10 {
    padding-bottom: 10px;
  }

  .pl_lg--10 {
    padding-left: 10px;
  }

  .pr_lg--10 {
    padding-right: 10px;
  }

  .mt_lg--10 {
    margin-top: 10px;
  }

  .mb_lg--10 {
    margin-bottom: 10px;
  }

  .ptb_lg--15 {
    padding: 15px 0;
  }

  .plr_lg--15 {
    padding: 0 15px;
  }

  .pt_lg--15 {
    padding-top: 15px;
  }

  .pb_lg--15 {
    padding-bottom: 15px;
  }

  .pl_lg--15 {
    padding-left: 15px;
  }

  .pr_lg--15 {
    padding-right: 15px;
  }

  .mt_lg--15 {
    margin-top: 15px;
  }

  .mb_lg--15 {
    margin-bottom: 15px;
  }

  .ptb_lg--20 {
    padding: 20px 0;
  }

  .plr_lg--20 {
    padding: 0 20px;
  }

  .pt_lg--20 {
    padding-top: 20px;
  }

  .pb_lg--20 {
    padding-bottom: 20px;
  }

  .pl_lg--20 {
    padding-left: 20px;
  }

  .pr_lg--20 {
    padding-right: 20px;
  }

  .mt_lg--20 {
    margin-top: 20px;
  }

  .mb_lg--20 {
    margin-bottom: 20px;
  }

  .ptb_lg--25 {
    padding: 25px 0;
  }

  .plr_lg--25 {
    padding: 0 25px;
  }

  .pt_lg--25 {
    padding-top: 25px;
  }

  .pb_lg--25 {
    padding-bottom: 25px;
  }

  .pl_lg--25 {
    padding-left: 25px;
  }

  .pr_lg--25 {
    padding-right: 25px;
  }

  .mt_lg--25 {
    margin-top: 25px;
  }

  .mb_lg--25 {
    margin-bottom: 25px;
  }

  .ptb_lg--30 {
    padding: 30px 0;
  }

  .plr_lg--30 {
    padding: 0 30px;
  }

  .pt_lg--30 {
    padding-top: 30px;
  }

  .pb_lg--30 {
    padding-bottom: 30px;
  }

  .pl_lg--30 {
    padding-left: 30px;
  }

  .pr_lg--30 {
    padding-right: 30px;
  }

  .mt_lg--30 {
    margin-top: 30px;
  }

  .mb_lg--30 {
    margin-bottom: 30px;
  }

  .ptb_lg--35 {
    padding: 35px 0;
  }

  .plr_lg--35 {
    padding: 0 35px;
  }

  .pt_lg--35 {
    padding-top: 35px;
  }

  .pb_lg--35 {
    padding-bottom: 35px;
  }

  .pl_lg--35 {
    padding-left: 35px;
  }

  .pr_lg--35 {
    padding-right: 35px;
  }

  .mt_lg--35 {
    margin-top: 35px;
  }

  .mb_lg--35 {
    margin-bottom: 35px;
  }

  .ptb_lg--40 {
    padding: 40px 0;
  }

  .plr_lg--40 {
    padding: 0 40px;
  }

  .pt_lg--40 {
    padding-top: 40px;
  }

  .pb_lg--40 {
    padding-bottom: 40px;
  }

  .pl_lg--40 {
    padding-left: 40px;
  }

  .pr_lg--40 {
    padding-right: 40px;
  }

  .mt_lg--40 {
    margin-top: 40px;
  }

  .mb_lg--40 {
    margin-bottom: 40px;
  }

  .ptb_lg--45 {
    padding: 45px 0;
  }

  .plr_lg--45 {
    padding: 0 45px;
  }

  .pt_lg--45 {
    padding-top: 45px;
  }

  .pb_lg--45 {
    padding-bottom: 45px;
  }

  .pl_lg--45 {
    padding-left: 45px;
  }

  .pr_lg--45 {
    padding-right: 45px;
  }

  .mt_lg--45 {
    margin-top: 45px;
  }

  .mb_lg--45 {
    margin-bottom: 45px;
  }

  .ptb_lg--50 {
    padding: 50px 0;
  }

  .plr_lg--50 {
    padding: 0 50px;
  }

  .pt_lg--50 {
    padding-top: 50px;
  }

  .pb_lg--50 {
    padding-bottom: 50px;
  }

  .pl_lg--50 {
    padding-left: 50px;
  }

  .pr_lg--50 {
    padding-right: 50px;
  }

  .mt_lg--50 {
    margin-top: 50px;
  }

  .mb_lg--50 {
    margin-bottom: 50px;
  }

  .ptb_lg--55 {
    padding: 55px 0;
  }

  .plr_lg--55 {
    padding: 0 55px;
  }

  .pt_lg--55 {
    padding-top: 55px;
  }

  .pb_lg--55 {
    padding-bottom: 55px;
  }

  .pl_lg--55 {
    padding-left: 55px;
  }

  .pr_lg--55 {
    padding-right: 55px;
  }

  .mt_lg--55 {
    margin-top: 55px;
  }

  .mb_lg--55 {
    margin-bottom: 55px;
  }

  .ptb_lg--60 {
    padding: 60px 0;
  }

  .plr_lg--60 {
    padding: 0 60px;
  }

  .pt_lg--60 {
    padding-top: 60px;
  }

  .pb_lg--60 {
    padding-bottom: 60px;
  }

  .pl_lg--60 {
    padding-left: 60px;
  }

  .pr_lg--60 {
    padding-right: 60px;
  }

  .mt_lg--60 {
    margin-top: 60px;
  }

  .mb_lg--60 {
    margin-bottom: 60px;
  }

  .ptb_lg--65 {
    padding: 65px 0;
  }

  .plr_lg--65 {
    padding: 0 65px;
  }

  .pt_lg--65 {
    padding-top: 65px;
  }

  .pb_lg--65 {
    padding-bottom: 65px;
  }

  .pl_lg--65 {
    padding-left: 65px;
  }

  .pr_lg--65 {
    padding-right: 65px;
  }

  .mt_lg--65 {
    margin-top: 65px;
  }

  .mb_lg--65 {
    margin-bottom: 65px;
  }

  .ptb_lg--70 {
    padding: 70px 0;
  }

  .plr_lg--70 {
    padding: 0 70px;
  }

  .pt_lg--70 {
    padding-top: 70px;
  }

  .pb_lg--70 {
    padding-bottom: 70px;
  }

  .pl_lg--70 {
    padding-left: 70px;
  }

  .pr_lg--70 {
    padding-right: 70px;
  }

  .mt_lg--70 {
    margin-top: 70px;
  }

  .mb_lg--70 {
    margin-bottom: 70px;
  }

  .ptb_lg--75 {
    padding: 75px 0;
  }

  .plr_lg--75 {
    padding: 0 75px;
  }

  .pt_lg--75 {
    padding-top: 75px;
  }

  .pb_lg--75 {
    padding-bottom: 75px;
  }

  .pl_lg--75 {
    padding-left: 75px;
  }

  .pr_lg--75 {
    padding-right: 75px;
  }

  .mt_lg--75 {
    margin-top: 75px;
  }

  .mb_lg--75 {
    margin-bottom: 75px;
  }

  .ptb_lg--80 {
    padding: 80px 0;
  }

  .plr_lg--80 {
    padding: 0 80px;
  }

  .pt_lg--80 {
    padding-top: 80px;
  }

  .pb_lg--80 {
    padding-bottom: 80px;
  }

  .pl_lg--80 {
    padding-left: 80px;
  }

  .pr_lg--80 {
    padding-right: 80px;
  }

  .mt_lg--80 {
    margin-top: 80px;
  }

  .mb_lg--80 {
    margin-bottom: 80px;
  }

  .ptb_lg--85 {
    padding: 85px 0;
  }

  .plr_lg--85 {
    padding: 0 85px;
  }

  .pt_lg--85 {
    padding-top: 85px;
  }

  .pb_lg--85 {
    padding-bottom: 85px;
  }

  .pl_lg--85 {
    padding-left: 85px;
  }

  .pr_lg--85 {
    padding-right: 85px;
  }

  .mt_lg--85 {
    margin-top: 85px;
  }

  .mb_lg--85 {
    margin-bottom: 85px;
  }

  .ptb_lg--90 {
    padding: 90px 0;
  }

  .plr_lg--90 {
    padding: 0 90px;
  }

  .pt_lg--90 {
    padding-top: 90px;
  }

  .pb_lg--90 {
    padding-bottom: 90px;
  }

  .pl_lg--90 {
    padding-left: 90px;
  }

  .pr_lg--90 {
    padding-right: 90px;
  }

  .mt_lg--90 {
    margin-top: 90px;
  }

  .mb_lg--90 {
    margin-bottom: 90px;
  }

  .ptb_lg--95 {
    padding: 95px 0;
  }

  .plr_lg--95 {
    padding: 0 95px;
  }

  .pt_lg--95 {
    padding-top: 95px;
  }

  .pb_lg--95 {
    padding-bottom: 95px;
  }

  .pl_lg--95 {
    padding-left: 95px;
  }

  .pr_lg--95 {
    padding-right: 95px;
  }

  .mt_lg--95 {
    margin-top: 95px;
  }

  .mb_lg--95 {
    margin-bottom: 95px;
  }

  .ptb_lg--100 {
    padding: 100px 0;
  }

  .plr_lg--100 {
    padding: 0 100px;
  }

  .pt_lg--100 {
    padding-top: 100px;
  }

  .pb_lg--100 {
    padding-bottom: 100px;
  }

  .pl_lg--100 {
    padding-left: 100px;
  }

  .pr_lg--100 {
    padding-right: 100px;
  }

  .mt_lg--100 {
    margin-top: 100px;
  }

  .mb_lg--100 {
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb_md--0 {
    padding: 0;
  }

  .pl_md--0 {
    padding-left: 0;
  }

  .pr_md--0 {
    padding-right: 0;
  }

  .pt_md--0 {
    padding-top: 0;
  }

  .pb_md--0 {
    padding-bottom: 0;
  }

  .mr_md--0 {
    margin-right: 0;
  }

  .ml_md--0 {
    margin-left: 0;
  }

  .mt_md--0 {
    margin-top: 0;
  }

  .mb_md--0 {
    margin-bottom: 0;
  }

  .ptb_md--250 {
    padding: 250px 0;
  }

  .ptb_md--5 {
    padding: 5px 0 !important;
  }

  .plr_md--5 {
    padding: 0 5px !important;
  }

  .pt_md--5 {
    padding-top: 5px !important;
  }

  .pb_md--5 {
    padding-bottom: 5px !important;
  }

  .pl_md--5 {
    padding-left: 5px !important;
  }

  .pr_md--5 {
    padding-right: 5px !important;
  }

  .mt_md--5 {
    margin-top: 5px !important;
  }

  .mb_md--5 {
    margin-bottom: 5px !important;
  }

  .ptb_md--10 {
    padding: 10px 0 !important;
  }

  .plr_md--10 {
    padding: 0 10px !important;
  }

  .pt_md--10 {
    padding-top: 10px !important;
  }

  .pb_md--10 {
    padding-bottom: 10px !important;
  }

  .pl_md--10 {
    padding-left: 10px !important;
  }

  .pr_md--10 {
    padding-right: 10px !important;
  }

  .mt_md--10 {
    margin-top: 10px !important;
  }

  .mb_md--10 {
    margin-bottom: 10px !important;
  }

  .ptb_md--15 {
    padding: 15px 0 !important;
  }

  .plr_md--15 {
    padding: 0 15px !important;
  }

  .pt_md--15 {
    padding-top: 15px !important;
  }

  .pb_md--15 {
    padding-bottom: 15px !important;
  }

  .pl_md--15 {
    padding-left: 15px !important;
  }

  .pr_md--15 {
    padding-right: 15px !important;
  }

  .mt_md--15 {
    margin-top: 15px !important;
  }

  .mb_md--15 {
    margin-bottom: 15px !important;
  }

  .ptb_md--20 {
    padding: 20px 0 !important;
  }

  .plr_md--20 {
    padding: 0 20px !important;
  }

  .pt_md--20 {
    padding-top: 20px !important;
  }

  .pb_md--20 {
    padding-bottom: 20px !important;
  }

  .pl_md--20 {
    padding-left: 20px !important;
  }

  .pr_md--20 {
    padding-right: 20px !important;
  }

  .mt_md--20 {
    margin-top: 20px !important;
  }

  .mb_md--20 {
    margin-bottom: 20px !important;
  }

  .ptb_md--25 {
    padding: 25px 0 !important;
  }

  .plr_md--25 {
    padding: 0 25px !important;
  }

  .pt_md--25 {
    padding-top: 25px !important;
  }

  .pb_md--25 {
    padding-bottom: 25px !important;
  }

  .pl_md--25 {
    padding-left: 25px !important;
  }

  .pr_md--25 {
    padding-right: 25px !important;
  }

  .mt_md--25 {
    margin-top: 25px !important;
  }

  .mb_md--25 {
    margin-bottom: 25px !important;
  }

  .ptb_md--30 {
    padding: 30px 0 !important;
  }

  .plr_md--30 {
    padding: 0 30px !important;
  }

  .pt_md--30 {
    padding-top: 30px !important;
  }

  .pb_md--30 {
    padding-bottom: 30px !important;
  }

  .pl_md--30 {
    padding-left: 30px !important;
  }

  .pr_md--30 {
    padding-right: 30px !important;
  }

  .mt_md--30 {
    margin-top: 30px !important;
  }

  .mb_md--30 {
    margin-bottom: 30px !important;
  }

  .ptb_md--35 {
    padding: 35px 0 !important;
  }

  .plr_md--35 {
    padding: 0 35px !important;
  }

  .pt_md--35 {
    padding-top: 35px !important;
  }

  .pb_md--35 {
    padding-bottom: 35px !important;
  }

  .pl_md--35 {
    padding-left: 35px !important;
  }

  .pr_md--35 {
    padding-right: 35px !important;
  }

  .mt_md--35 {
    margin-top: 35px !important;
  }

  .mb_md--35 {
    margin-bottom: 35px !important;
  }

  .ptb_md--40 {
    padding: 40px 0 !important;
  }

  .plr_md--40 {
    padding: 0 40px !important;
  }

  .pt_md--40 {
    padding-top: 40px !important;
  }

  .pb_md--40 {
    padding-bottom: 40px !important;
  }

  .pl_md--40 {
    padding-left: 40px !important;
  }

  .pr_md--40 {
    padding-right: 40px !important;
  }

  .mt_md--40 {
    margin-top: 40px !important;
  }

  .mb_md--40 {
    margin-bottom: 40px !important;
  }

  .ptb_md--45 {
    padding: 45px 0 !important;
  }

  .plr_md--45 {
    padding: 0 45px !important;
  }

  .pt_md--45 {
    padding-top: 45px !important;
  }

  .pb_md--45 {
    padding-bottom: 45px !important;
  }

  .pl_md--45 {
    padding-left: 45px !important;
  }

  .pr_md--45 {
    padding-right: 45px !important;
  }

  .mt_md--45 {
    margin-top: 45px !important;
  }

  .mb_md--45 {
    margin-bottom: 45px !important;
  }

  .ptb_md--50 {
    padding: 50px 0 !important;
  }

  .plr_md--50 {
    padding: 0 50px !important;
  }

  .pt_md--50 {
    padding-top: 50px !important;
  }

  .pb_md--50 {
    padding-bottom: 50px !important;
  }

  .pl_md--50 {
    padding-left: 50px !important;
  }

  .pr_md--50 {
    padding-right: 50px !important;
  }

  .mt_md--50 {
    margin-top: 50px !important;
  }

  .mb_md--50 {
    margin-bottom: 50px !important;
  }

  .ptb_md--55 {
    padding: 55px 0 !important;
  }

  .plr_md--55 {
    padding: 0 55px !important;
  }

  .pt_md--55 {
    padding-top: 55px !important;
  }

  .pb_md--55 {
    padding-bottom: 55px !important;
  }

  .pl_md--55 {
    padding-left: 55px !important;
  }

  .pr_md--55 {
    padding-right: 55px !important;
  }

  .mt_md--55 {
    margin-top: 55px !important;
  }

  .mb_md--55 {
    margin-bottom: 55px !important;
  }

  .ptb_md--60 {
    padding: 60px 0 !important;
  }

  .plr_md--60 {
    padding: 0 60px !important;
  }

  .pt_md--60 {
    padding-top: 60px !important;
  }

  .pb_md--60 {
    padding-bottom: 60px !important;
  }

  .pl_md--60 {
    padding-left: 60px !important;
  }

  .pr_md--60 {
    padding-right: 60px !important;
  }

  .mt_md--60 {
    margin-top: 60px !important;
  }

  .mb_md--60 {
    margin-bottom: 60px !important;
  }

  .ptb_md--65 {
    padding: 65px 0 !important;
  }

  .plr_md--65 {
    padding: 0 65px !important;
  }

  .pt_md--65 {
    padding-top: 65px !important;
  }

  .pb_md--65 {
    padding-bottom: 65px !important;
  }

  .pl_md--65 {
    padding-left: 65px !important;
  }

  .pr_md--65 {
    padding-right: 65px !important;
  }

  .mt_md--65 {
    margin-top: 65px !important;
  }

  .mb_md--65 {
    margin-bottom: 65px !important;
  }

  .ptb_md--70 {
    padding: 70px 0 !important;
  }

  .plr_md--70 {
    padding: 0 70px !important;
  }

  .pt_md--70 {
    padding-top: 70px !important;
  }

  .pb_md--70 {
    padding-bottom: 70px !important;
  }

  .pl_md--70 {
    padding-left: 70px !important;
  }

  .pr_md--70 {
    padding-right: 70px !important;
  }

  .mt_md--70 {
    margin-top: 70px !important;
  }

  .mb_md--70 {
    margin-bottom: 70px !important;
  }

  .ptb_md--75 {
    padding: 75px 0 !important;
  }

  .plr_md--75 {
    padding: 0 75px !important;
  }

  .pt_md--75 {
    padding-top: 75px !important;
  }

  .pb_md--75 {
    padding-bottom: 75px !important;
  }

  .pl_md--75 {
    padding-left: 75px !important;
  }

  .pr_md--75 {
    padding-right: 75px !important;
  }

  .mt_md--75 {
    margin-top: 75px !important;
  }

  .mb_md--75 {
    margin-bottom: 75px !important;
  }

  .ptb_md--80 {
    padding: 80px 0 !important;
  }

  .plr_md--80 {
    padding: 0 80px !important;
  }

  .pt_md--80 {
    padding-top: 80px !important;
  }

  .pb_md--80 {
    padding-bottom: 80px !important;
  }

  .pl_md--80 {
    padding-left: 80px !important;
  }

  .pr_md--80 {
    padding-right: 80px !important;
  }

  .mt_md--80 {
    margin-top: 80px !important;
  }

  .mb_md--80 {
    margin-bottom: 80px !important;
  }

  .ptb_md--85 {
    padding: 85px 0 !important;
  }

  .plr_md--85 {
    padding: 0 85px !important;
  }

  .pt_md--85 {
    padding-top: 85px !important;
  }

  .pb_md--85 {
    padding-bottom: 85px !important;
  }

  .pl_md--85 {
    padding-left: 85px !important;
  }

  .pr_md--85 {
    padding-right: 85px !important;
  }

  .mt_md--85 {
    margin-top: 85px !important;
  }

  .mb_md--85 {
    margin-bottom: 85px !important;
  }

  .ptb_md--90 {
    padding: 90px 0 !important;
  }

  .plr_md--90 {
    padding: 0 90px !important;
  }

  .pt_md--90 {
    padding-top: 90px !important;
  }

  .pb_md--90 {
    padding-bottom: 90px !important;
  }

  .pl_md--90 {
    padding-left: 90px !important;
  }

  .pr_md--90 {
    padding-right: 90px !important;
  }

  .mt_md--90 {
    margin-top: 90px !important;
  }

  .mb_md--90 {
    margin-bottom: 90px !important;
  }

  .ptb_md--95 {
    padding: 95px 0 !important;
  }

  .plr_md--95 {
    padding: 0 95px !important;
  }

  .pt_md--95 {
    padding-top: 95px !important;
  }

  .pb_md--95 {
    padding-bottom: 95px !important;
  }

  .pl_md--95 {
    padding-left: 95px !important;
  }

  .pr_md--95 {
    padding-right: 95px !important;
  }

  .mt_md--95 {
    margin-top: 95px !important;
  }

  .mb_md--95 {
    margin-bottom: 95px !important;
  }

  .ptb_md--100 {
    padding: 100px 0 !important;
  }

  .plr_md--100 {
    padding: 0 100px !important;
  }

  .pt_md--100 {
    padding-top: 100px !important;
  }

  .pb_md--100 {
    padding-bottom: 100px !important;
  }

  .pl_md--100 {
    padding-left: 100px !important;
  }

  .pr_md--100 {
    padding-right: 100px !important;
  }

  .mt_md--100 {
    margin-top: 100px !important;
  }

  .mb_md--100 {
    margin-bottom: 100px !important;
  }
}

@media only screen and (max-width: 767px) {
  .ptb_sm--250 {
    padding: 250px 0 !important;
  }

  .ptb_sm--0 {
    padding: 0 !important;
  }

  .pl_sm--0 {
    padding-left: 0 !important;
  }

  .pr_sm--0 {
    padding-right: 0 !important;
  }

  .pt_sm--0 {
    padding-top: 0 !important;
  }

  .pb_sm--0 {
    padding-bottom: 0 !important;
  }

  .mr_sm--0 {
    margin-right: 0 !important;
  }

  .ml_sm--0 {
    margin-left: 0 !important;
  }

  .mt_sm--0 {
    margin-top: 0 !important;
  }

  .mb_sm--0 {
    margin-bottom: 0 !important;
  }

  .ptb_sm--5 {
    padding: 5px 0 !important;
  }

  .plr_sm--5 {
    padding: 0 5px !important;
  }

  .pt_sm--5 {
    padding-top: 5px !important;
  }

  .pb_sm--5 {
    padding-bottom: 5px !important;
  }

  .pl_sm--5 {
    padding-left: 5px !important;
  }

  .pr_sm--5 {
    padding-right: 5px !important;
  }

  .mt_sm--5 {
    margin-top: 5px !important;
  }

  .mb_sm--5 {
    margin-bottom: 5px !important;
  }

  .ptb_sm--10 {
    padding: 10px 0 !important;
  }

  .plr_sm--10 {
    padding: 0 10px !important;
  }

  .pt_sm--10 {
    padding-top: 10px !important;
  }

  .pb_sm--10 {
    padding-bottom: 10px !important;
  }

  .pl_sm--10 {
    padding-left: 10px !important;
  }

  .pr_sm--10 {
    padding-right: 10px !important;
  }

  .mt_sm--10 {
    margin-top: 10px !important;
  }

  .mb_sm--10 {
    margin-bottom: 10px !important;
  }

  .ptb_sm--15 {
    padding: 15px 0 !important;
  }

  .plr_sm--15 {
    padding: 0 15px !important;
  }

  .pt_sm--15 {
    padding-top: 15px !important;
  }

  .pb_sm--15 {
    padding-bottom: 15px !important;
  }

  .pl_sm--15 {
    padding-left: 15px !important;
  }

  .pr_sm--15 {
    padding-right: 15px !important;
  }

  .mt_sm--15 {
    margin-top: 15px !important;
  }

  .mb_sm--15 {
    margin-bottom: 15px !important;
  }

  .ptb_sm--20 {
    padding: 20px 0 !important;
  }

  .plr_sm--20 {
    padding: 0 20px !important;
  }

  .pt_sm--20 {
    padding-top: 20px !important;
  }

  .pb_sm--20 {
    padding-bottom: 20px !important;
  }

  .pl_sm--20 {
    padding-left: 20px !important;
  }

  .pr_sm--20 {
    padding-right: 20px !important;
  }

  .mt_sm--20 {
    margin-top: 20px !important;
  }

  .mb_sm--20 {
    margin-bottom: 20px !important;
  }

  .ptb_sm--25 {
    padding: 25px 0 !important;
  }

  .plr_sm--25 {
    padding: 0 25px !important;
  }

  .pt_sm--25 {
    padding-top: 25px !important;
  }

  .pb_sm--25 {
    padding-bottom: 25px !important;
  }

  .pl_sm--25 {
    padding-left: 25px !important;
  }

  .pr_sm--25 {
    padding-right: 25px !important;
  }

  .mt_sm--25 {
    margin-top: 25px !important;
  }

  .mb_sm--25 {
    margin-bottom: 25px !important;
  }

  .ptb_sm--30 {
    padding: 30px 0 !important;
  }

  .plr_sm--30 {
    padding: 0 30px !important;
  }

  .pt_sm--30 {
    padding-top: 30px !important;
  }

  .pb_sm--30 {
    padding-bottom: 30px !important;
  }

  .pl_sm--30 {
    padding-left: 30px !important;
  }

  .pr_sm--30 {
    padding-right: 30px !important;
  }

  .mt_sm--30 {
    margin-top: 30px !important;
  }

  .mb_sm--30 {
    margin-bottom: 30px !important;
  }

  .ptb_sm--35 {
    padding: 35px 0 !important;
  }

  .plr_sm--35 {
    padding: 0 35px !important;
  }

  .pt_sm--35 {
    padding-top: 35px !important;
  }

  .pb_sm--35 {
    padding-bottom: 35px !important;
  }

  .pl_sm--35 {
    padding-left: 35px !important;
  }

  .pr_sm--35 {
    padding-right: 35px !important;
  }

  .mt_sm--35 {
    margin-top: 35px !important;
  }

  .mb_sm--35 {
    margin-bottom: 35px !important;
  }

  .ptb_sm--40 {
    padding: 40px 0 !important;
  }

  .plr_sm--40 {
    padding: 0 40px !important;
  }

  .pt_sm--40 {
    padding-top: 40px !important;
  }

  .pb_sm--40 {
    padding-bottom: 40px !important;
  }

  .pl_sm--40 {
    padding-left: 40px !important;
  }

  .pr_sm--40 {
    padding-right: 40px !important;
  }

  .mt_sm--40 {
    margin-top: 40px !important;
  }

  .mb_sm--40 {
    margin-bottom: 40px !important;
  }

  .ptb_sm--45 {
    padding: 45px 0 !important;
  }

  .plr_sm--45 {
    padding: 0 45px !important;
  }

  .pt_sm--45 {
    padding-top: 45px !important;
  }

  .pb_sm--45 {
    padding-bottom: 45px !important;
  }

  .pl_sm--45 {
    padding-left: 45px !important;
  }

  .pr_sm--45 {
    padding-right: 45px !important;
  }

  .mt_sm--45 {
    margin-top: 45px !important;
  }

  .mb_sm--45 {
    margin-bottom: 45px !important;
  }

  .ptb_sm--50 {
    padding: 50px 0 !important;
  }

  .plr_sm--50 {
    padding: 0 50px !important;
  }

  .pt_sm--50 {
    padding-top: 50px !important;
  }

  .pb_sm--50 {
    padding-bottom: 50px !important;
  }

  .pl_sm--50 {
    padding-left: 50px !important;
  }

  .pr_sm--50 {
    padding-right: 50px !important;
  }

  .mt_sm--50 {
    margin-top: 50px !important;
  }

  .mb_sm--50 {
    margin-bottom: 50px !important;
  }

  .ptb_sm--55 {
    padding: 55px 0 !important;
  }

  .plr_sm--55 {
    padding: 0 55px !important;
  }

  .pt_sm--55 {
    padding-top: 55px !important;
  }

  .pb_sm--55 {
    padding-bottom: 55px !important;
  }

  .pl_sm--55 {
    padding-left: 55px !important;
  }

  .pr_sm--55 {
    padding-right: 55px !important;
  }

  .mt_sm--55 {
    margin-top: 55px !important;
  }

  .mb_sm--55 {
    margin-bottom: 55px !important;
  }

  .ptb_sm--60 {
    padding: 60px 0 !important;
  }

  .plr_sm--60 {
    padding: 0 60px !important;
  }

  .pt_sm--60 {
    padding-top: 60px !important;
  }

  .pb_sm--60 {
    padding-bottom: 60px !important;
  }

  .pl_sm--60 {
    padding-left: 60px !important;
  }

  .pr_sm--60 {
    padding-right: 60px !important;
  }

  .mt_sm--60 {
    margin-top: 60px !important;
  }

  .mb_sm--60 {
    margin-bottom: 60px !important;
  }

  .ptb_sm--65 {
    padding: 65px 0 !important;
  }

  .plr_sm--65 {
    padding: 0 65px !important;
  }

  .pt_sm--65 {
    padding-top: 65px !important;
  }

  .pb_sm--65 {
    padding-bottom: 65px !important;
  }

  .pl_sm--65 {
    padding-left: 65px !important;
  }

  .pr_sm--65 {
    padding-right: 65px !important;
  }

  .mt_sm--65 {
    margin-top: 65px !important;
  }

  .mb_sm--65 {
    margin-bottom: 65px !important;
  }

  .ptb_sm--70 {
    padding: 70px 0 !important;
  }

  .plr_sm--70 {
    padding: 0 70px !important;
  }

  .pt_sm--70 {
    padding-top: 70px !important;
  }

  .pb_sm--70 {
    padding-bottom: 70px !important;
  }

  .pl_sm--70 {
    padding-left: 70px !important;
  }

  .pr_sm--70 {
    padding-right: 70px !important;
  }

  .mt_sm--70 {
    margin-top: 70px !important;
  }

  .mb_sm--70 {
    margin-bottom: 70px !important;
  }

  .ptb_sm--75 {
    padding: 75px 0 !important;
  }

  .plr_sm--75 {
    padding: 0 75px !important;
  }

  .pt_sm--75 {
    padding-top: 75px !important;
  }

  .pb_sm--75 {
    padding-bottom: 75px !important;
  }

  .pl_sm--75 {
    padding-left: 75px !important;
  }

  .pr_sm--75 {
    padding-right: 75px !important;
  }

  .mt_sm--75 {
    margin-top: 75px !important;
  }

  .mb_sm--75 {
    margin-bottom: 75px !important;
  }

  .ptb_sm--80 {
    padding: 80px 0 !important;
  }

  .plr_sm--80 {
    padding: 0 80px !important;
  }

  .pt_sm--80 {
    padding-top: 80px !important;
  }

  .pb_sm--80 {
    padding-bottom: 80px !important;
  }

  .pl_sm--80 {
    padding-left: 80px !important;
  }

  .pr_sm--80 {
    padding-right: 80px !important;
  }

  .mt_sm--80 {
    margin-top: 80px !important;
  }

  .mb_sm--80 {
    margin-bottom: 80px !important;
  }

  .ptb_sm--85 {
    padding: 85px 0 !important;
  }

  .plr_sm--85 {
    padding: 0 85px !important;
  }

  .pt_sm--85 {
    padding-top: 85px !important;
  }

  .pb_sm--85 {
    padding-bottom: 85px !important;
  }

  .pl_sm--85 {
    padding-left: 85px !important;
  }

  .pr_sm--85 {
    padding-right: 85px !important;
  }

  .mt_sm--85 {
    margin-top: 85px !important;
  }

  .mb_sm--85 {
    margin-bottom: 85px !important;
  }

  .ptb_sm--90 {
    padding: 90px 0 !important;
  }

  .plr_sm--90 {
    padding: 0 90px !important;
  }

  .pt_sm--90 {
    padding-top: 90px !important;
  }

  .pb_sm--90 {
    padding-bottom: 90px !important;
  }

  .pl_sm--90 {
    padding-left: 90px !important;
  }

  .pr_sm--90 {
    padding-right: 90px !important;
  }

  .mt_sm--90 {
    margin-top: 90px !important;
  }

  .mb_sm--90 {
    margin-bottom: 90px !important;
  }

  .ptb_sm--95 {
    padding: 95px 0 !important;
  }

  .plr_sm--95 {
    padding: 0 95px !important;
  }

  .pt_sm--95 {
    padding-top: 95px !important;
  }

  .pb_sm--95 {
    padding-bottom: 95px !important;
  }

  .pl_sm--95 {
    padding-left: 95px !important;
  }

  .pr_sm--95 {
    padding-right: 95px !important;
  }

  .mt_sm--95 {
    margin-top: 95px !important;
  }

  .mb_sm--95 {
    margin-bottom: 95px !important;
  }

  .ptb_sm--100 {
    padding: 100px 0 !important;
  }

  .plr_sm--100 {
    padding: 0 100px !important;
  }

  .pt_sm--100 {
    padding-top: 100px !important;
  }

  .pb_sm--100 {
    padding-bottom: 100px !important;
  }

  .pl_sm--100 {
    padding-left: 100px !important;
  }

  .pr_sm--100 {
    padding-right: 100px !important;
  }

  .mt_sm--100 {
    margin-top: 100px !important;
  }

  .mb_sm--100 {
    margin-bottom: 100px !important;
  }

  .pl_sm--0 {
    padding-left: 0;
  }

  .pr_sm--0 {
    padding-right: 0;
  }

  .pt_sm--0 {
    padding-top: 0;
  }

  .pb_sm--0 {
    padding-bottom: 0;
  }

  .mr_sm--0 {
    margin-right: 0;
  }

  .ml_sm--0 {
    margin-left: 0;
  }

  .mt_sm--0 {
    margin-top: 0;
  }

  .mb_sm--0 {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 575px) {
  .ptb_mobile--5 {
    padding: 5px 0 !important;
  }

  .plr_mobile--5 {
    padding: 0 5px !important;
  }

  .pt_mobile--5 {
    padding-top: 5px !important;
  }

  .pb_mobile--5 {
    padding-bottom: 5px !important;
  }

  .pl_mobile--5 {
    padding-left: 5px !important;
  }

  .pr_mobile--5 {
    padding-right: 5px !important;
  }

  .mt_mobile--5 {
    margin-top: 5px !important;
  }

  .mb_mobile--5 {
    margin-bottom: 5px !important;
  }

  .ptb_mobile--10 {
    padding: 10px 0 !important;
  }

  .plr_mobile--10 {
    padding: 0 10px !important;
  }

  .pt_mobile--10 {
    padding-top: 10px !important;
  }

  .pb_mobile--10 {
    padding-bottom: 10px !important;
  }

  .pl_mobile--10 {
    padding-left: 10px !important;
  }

  .pr_mobile--10 {
    padding-right: 10px !important;
  }

  .mt_mobile--10 {
    margin-top: 10px !important;
  }

  .mb_mobile--10 {
    margin-bottom: 10px !important;
  }

  .ptb_mobile--15 {
    padding: 15px 0 !important;
  }

  .plr_mobile--15 {
    padding: 0 15px !important;
  }

  .pt_mobile--15 {
    padding-top: 15px !important;
  }

  .pb_mobile--15 {
    padding-bottom: 15px !important;
  }

  .pl_mobile--15 {
    padding-left: 15px !important;
  }

  .pr_mobile--15 {
    padding-right: 15px !important;
  }

  .mt_mobile--15 {
    margin-top: 15px !important;
  }

  .mb_mobile--15 {
    margin-bottom: 15px !important;
  }

  .ptb_mobile--20 {
    padding: 20px 0 !important;
  }

  .plr_mobile--20 {
    padding: 0 20px !important;
  }

  .pt_mobile--20 {
    padding-top: 20px !important;
  }

  .pb_mobile--20 {
    padding-bottom: 20px !important;
  }

  .pl_mobile--20 {
    padding-left: 20px !important;
  }

  .pr_mobile--20 {
    padding-right: 20px !important;
  }

  .mt_mobile--20 {
    margin-top: 20px !important;
  }

  .mb_mobile--20 {
    margin-bottom: 20px !important;
  }

  .ptb_mobile--25 {
    padding: 25px 0 !important;
  }

  .plr_mobile--25 {
    padding: 0 25px !important;
  }

  .pt_mobile--25 {
    padding-top: 25px !important;
  }

  .pb_mobile--25 {
    padding-bottom: 25px !important;
  }

  .pl_mobile--25 {
    padding-left: 25px !important;
  }

  .pr_mobile--25 {
    padding-right: 25px !important;
  }

  .mt_mobile--25 {
    margin-top: 25px !important;
  }

  .mb_mobile--25 {
    margin-bottom: 25px !important;
  }

  .ptb_mobile--30 {
    padding: 30px 0 !important;
  }

  .plr_mobile--30 {
    padding: 0 30px !important;
  }

  .pt_mobile--30 {
    padding-top: 30px !important;
  }

  .pb_mobile--30 {
    padding-bottom: 30px !important;
  }

  .pl_mobile--30 {
    padding-left: 30px !important;
  }

  .pr_mobile--30 {
    padding-right: 30px !important;
  }

  .mt_mobile--30 {
    margin-top: 30px !important;
  }

  .mb_mobile--30 {
    margin-bottom: 30px !important;
  }

  .ptb_mobile--35 {
    padding: 35px 0 !important;
  }

  .plr_mobile--35 {
    padding: 0 35px !important;
  }

  .pt_mobile--35 {
    padding-top: 35px !important;
  }

  .pb_mobile--35 {
    padding-bottom: 35px !important;
  }

  .pl_mobile--35 {
    padding-left: 35px !important;
  }

  .pr_mobile--35 {
    padding-right: 35px !important;
  }

  .mt_mobile--35 {
    margin-top: 35px !important;
  }

  .mb_mobile--35 {
    margin-bottom: 35px !important;
  }

  .ptb_mobile--40 {
    padding: 40px 0 !important;
  }

  .plr_mobile--40 {
    padding: 0 40px !important;
  }

  .pt_mobile--40 {
    padding-top: 40px !important;
  }

  .pb_mobile--40 {
    padding-bottom: 40px !important;
  }

  .pl_mobile--40 {
    padding-left: 40px !important;
  }

  .pr_mobile--40 {
    padding-right: 40px !important;
  }

  .mt_mobile--40 {
    margin-top: 40px !important;
  }

  .mb_mobile--40 {
    margin-bottom: 40px !important;
  }

  .ptb_mobile--45 {
    padding: 45px 0 !important;
  }

  .plr_mobile--45 {
    padding: 0 45px !important;
  }

  .pt_mobile--45 {
    padding-top: 45px !important;
  }

  .pb_mobile--45 {
    padding-bottom: 45px !important;
  }

  .pl_mobile--45 {
    padding-left: 45px !important;
  }

  .pr_mobile--45 {
    padding-right: 45px !important;
  }

  .mt_mobile--45 {
    margin-top: 45px !important;
  }

  .mb_mobile--45 {
    margin-bottom: 45px !important;
  }

  .ptb_mobile--50 {
    padding: 50px 0 !important;
  }

  .plr_mobile--50 {
    padding: 0 50px !important;
  }

  .pt_mobile--50 {
    padding-top: 50px !important;
  }

  .pb_mobile--50 {
    padding-bottom: 50px !important;
  }

  .pl_mobile--50 {
    padding-left: 50px !important;
  }

  .pr_mobile--50 {
    padding-right: 50px !important;
  }

  .mt_mobile--50 {
    margin-top: 50px !important;
  }

  .mb_mobile--50 {
    margin-bottom: 50px !important;
  }

  .ptb_mobile--55 {
    padding: 55px 0 !important;
  }

  .plr_mobile--55 {
    padding: 0 55px !important;
  }

  .pt_mobile--55 {
    padding-top: 55px !important;
  }

  .pb_mobile--55 {
    padding-bottom: 55px !important;
  }

  .pl_mobile--55 {
    padding-left: 55px !important;
  }

  .pr_mobile--55 {
    padding-right: 55px !important;
  }

  .mt_mobile--55 {
    margin-top: 55px !important;
  }

  .mb_mobile--55 {
    margin-bottom: 55px !important;
  }

  .ptb_mobile--60 {
    padding: 60px 0 !important;
  }

  .plr_mobile--60 {
    padding: 0 60px !important;
  }

  .pt_mobile--60 {
    padding-top: 60px !important;
  }

  .pb_mobile--60 {
    padding-bottom: 60px !important;
  }

  .pl_mobile--60 {
    padding-left: 60px !important;
  }

  .pr_mobile--60 {
    padding-right: 60px !important;
  }

  .mt_mobile--60 {
    margin-top: 60px !important;
  }

  .mb_mobile--60 {
    margin-bottom: 60px !important;
  }

  .ptb_mobile--65 {
    padding: 65px 0 !important;
  }

  .plr_mobile--65 {
    padding: 0 65px !important;
  }

  .pt_mobile--65 {
    padding-top: 65px !important;
  }

  .pb_mobile--65 {
    padding-bottom: 65px !important;
  }

  .pl_mobile--65 {
    padding-left: 65px !important;
  }

  .pr_mobile--65 {
    padding-right: 65px !important;
  }

  .mt_mobile--65 {
    margin-top: 65px !important;
  }

  .mb_mobile--65 {
    margin-bottom: 65px !important;
  }

  .ptb_mobile--70 {
    padding: 70px 0 !important;
  }

  .plr_mobile--70 {
    padding: 0 70px !important;
  }

  .pt_mobile--70 {
    padding-top: 70px !important;
  }

  .pb_mobile--70 {
    padding-bottom: 70px !important;
  }

  .pl_mobile--70 {
    padding-left: 70px !important;
  }

  .pr_mobile--70 {
    padding-right: 70px !important;
  }

  .mt_mobile--70 {
    margin-top: 70px !important;
  }

  .mb_mobile--70 {
    margin-bottom: 70px !important;
  }

  .ptb_mobile--75 {
    padding: 75px 0 !important;
  }

  .plr_mobile--75 {
    padding: 0 75px !important;
  }

  .pt_mobile--75 {
    padding-top: 75px !important;
  }

  .pb_mobile--75 {
    padding-bottom: 75px !important;
  }

  .pl_mobile--75 {
    padding-left: 75px !important;
  }

  .pr_mobile--75 {
    padding-right: 75px !important;
  }

  .mt_mobile--75 {
    margin-top: 75px !important;
  }

  .mb_mobile--75 {
    margin-bottom: 75px !important;
  }

  .ptb_mobile--80 {
    padding: 80px 0 !important;
  }

  .plr_mobile--80 {
    padding: 0 80px !important;
  }

  .pt_mobile--80 {
    padding-top: 80px !important;
  }

  .pb_mobile--80 {
    padding-bottom: 80px !important;
  }

  .pl_mobile--80 {
    padding-left: 80px !important;
  }

  .pr_mobile--80 {
    padding-right: 80px !important;
  }

  .mt_mobile--80 {
    margin-top: 80px !important;
  }

  .mb_mobile--80 {
    margin-bottom: 80px !important;
  }

  .ptb_mobile--85 {
    padding: 85px 0 !important;
  }

  .plr_mobile--85 {
    padding: 0 85px !important;
  }

  .pt_mobile--85 {
    padding-top: 85px !important;
  }

  .pb_mobile--85 {
    padding-bottom: 85px !important;
  }

  .pl_mobile--85 {
    padding-left: 85px !important;
  }

  .pr_mobile--85 {
    padding-right: 85px !important;
  }

  .mt_mobile--85 {
    margin-top: 85px !important;
  }

  .mb_mobile--85 {
    margin-bottom: 85px !important;
  }

  .ptb_mobile--90 {
    padding: 90px 0 !important;
  }

  .plr_mobile--90 {
    padding: 0 90px !important;
  }

  .pt_mobile--90 {
    padding-top: 90px !important;
  }

  .pb_mobile--90 {
    padding-bottom: 90px !important;
  }

  .pl_mobile--90 {
    padding-left: 90px !important;
  }

  .pr_mobile--90 {
    padding-right: 90px !important;
  }

  .mt_mobile--90 {
    margin-top: 90px !important;
  }

  .mb_mobile--90 {
    margin-bottom: 90px !important;
  }

  .ptb_mobile--95 {
    padding: 95px 0 !important;
  }

  .plr_mobile--95 {
    padding: 0 95px !important;
  }

  .pt_mobile--95 {
    padding-top: 95px !important;
  }

  .pb_mobile--95 {
    padding-bottom: 95px !important;
  }

  .pl_mobile--95 {
    padding-left: 95px !important;
  }

  .pr_mobile--95 {
    padding-right: 95px !important;
  }

  .mt_mobile--95 {
    margin-top: 95px !important;
  }

  .mb_mobile--95 {
    margin-bottom: 95px !important;
  }

  .ptb_mobile--100 {
    padding: 100px 0 !important;
  }

  .plr_mobile--100 {
    padding: 0 100px !important;
  }

  .pt_mobile--100 {
    padding-top: 100px !important;
  }

  .pb_mobile--100 {
    padding-bottom: 100px !important;
  }

  .pl_mobile--100 {
    padding-left: 100px !important;
  }

  .pr_mobile--100 {
    padding-right: 100px !important;
  }

  .mt_mobile--100 {
    margin-top: 100px !important;
  }

  .mb_mobile--100 {
    margin-bottom: 100px !important;
  }
}

.slick-gutter-5 {
  margin-left: -5px;
  margin-right: -5px;
}

.slick-gutter-5 .slick-slide {
  padding-left: 5px;
  padding-right: 5px;
}

.slick-gutter-10 {
  margin-left: -10px;
  margin-right: -10px;
}

.slick-gutter-10 .slick-slide {
  padding-left: 10px;
  padding-right: 10px;
}

.slick-gutter-15 {
  margin-left: -15px;
  margin-right: -15px;
}

.slick-gutter-15 .slick-slide {
  padding-left: 15px;
  padding-right: 15px;
}

.slick-gutter-20 {
  margin-left: -20px;
  margin-right: -20px;
}

.slick-gutter-20 .slick-slide {
  padding-left: 20px;
  padding-right: 20px;
}

.slick-gutter-25 {
  margin-left: -25px;
  margin-right: -25px;
}

.slick-gutter-25 .slick-slide {
  padding-left: 25px;
  padding-right: 25px;
}

.slick-gutter-30 {
  margin-left: -30px;
  margin-right: -30px;
}

.slick-gutter-30 .slick-slide {
  padding-left: 30px;
  padding-right: 30px;
}

.slick-gutter-35 {
  margin-left: -35px;
  margin-right: -35px;
}

.slick-gutter-35 .slick-slide {
  padding-left: 35px;
  padding-right: 35px;
}

.slick-gutter-40 {
  margin-left: -40px;
  margin-right: -40px;
}

.slick-gutter-40 .slick-slide {
  padding-left: 40px;
  padding-right: 40px;
}

.slick-gutter-45 {
  margin-left: -45px;
  margin-right: -45px;
}

.slick-gutter-45 .slick-slide {
  padding-left: 45px;
  padding-right: 45px;
}

.slick-gutter-50 {
  margin-left: -50px;
  margin-right: -50px;
}

.slick-gutter-50 .slick-slide {
  padding-left: 50px;
  padding-right: 50px;
}

.slick-gutter-55 {
  margin-left: -55px;
  margin-right: -55px;
}

.slick-gutter-55 .slick-slide {
  padding-left: 55px;
  padding-right: 55px;
}

.slick-gutter-60 {
  margin-left: -60px;
  margin-right: -60px;
}

.slick-gutter-60 .slick-slide {
  padding-left: 60px;
  padding-right: 60px;
}

.slick-gutter-65 {
  margin-left: -65px;
  margin-right: -65px;
}

.slick-gutter-65 .slick-slide {
  padding-left: 65px;
  padding-right: 65px;
}

.slick-gutter-70 {
  margin-left: -70px;
  margin-right: -70px;
}

.slick-gutter-70 .slick-slide {
  padding-left: 70px;
  padding-right: 70px;
}

.slick-gutter-75 {
  margin-left: -75px;
  margin-right: -75px;
}

.slick-gutter-75 .slick-slide {
  padding-left: 75px;
  padding-right: 75px;
}

.slick-gutter-80 {
  margin-left: -80px;
  margin-right: -80px;
}

.slick-gutter-80 .slick-slide {
  padding-left: 80px;
  padding-right: 80px;
}

.slick-gutter-85 {
  margin-left: -85px;
  margin-right: -85px;
}

.slick-gutter-85 .slick-slide {
  padding-left: 85px;
  padding-right: 85px;
}

.slick-gutter-90 {
  margin-left: -90px;
  margin-right: -90px;
}

.slick-gutter-90 .slick-slide {
  padding-left: 90px;
  padding-right: 90px;
}

.slick-gutter-95 {
  margin-left: -95px;
  margin-right: -95px;
}

.slick-gutter-95 .slick-slide {
  padding-left: 95px;
  padding-right: 95px;
}

.slick-gutter-100 {
  margin-left: -100px;
  margin-right: -100px;
}

.slick-gutter-100 .slick-slide {
  padding-left: 100px;
  padding-right: 100px;
}

.header-area.header--transparent {
  width: auto;
  z-index: 999;
  padding-left: 50px;
  padding-right: 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-area.header--transparent {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .header-area.header--transparent {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.header-area .header-wrapper {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .header-area .header-wrapper {
    padding: 15px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper {
    padding: 30px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-area .header-wrapper {
    padding: 30px 0;
  }
}

.header-area .header-wrapper .header-hamberger {
  align-items: center;
  display: flex;
}

.header-area .header-wrapper .header-hamberger .hamberger {
  margin-right: 27px;
}

.header-area .header-wrapper a.rn-btn {
  height: 46px;
  color: #c6c9d8;
  z-index: 2;
  border: 2px solid #b1b4c1;
  border-radius: 6px;
  padding: 0 25px;
  font-size: 14px;
  line-height: 42px;
  display: inline-block;
  position: relative;
}

.header-area .header-wrapper a.rn-btn.theme-color {
  color: #1889b5;
  border-color: #1889b5;
}

@media only screen and (max-width: 767px) {
  .header-area .header-wrapper a.rn-btn {
    height: 39px;
    padding: 0 11px;
    font-size: 12px;
    line-height: 33px;
  }
}

@media only screen and (max-width: 575px) {
  .header-area .header-wrapper a.rn-btn {
    height: 34px;
    line-height: 29px;
  }
}

.header-area .header-wrapper a.rn-btn:before {
  display: none;
}

.header-area .header-wrapper a.rn-btn:hover {
  color: #fff;
  background: #1889b5;
  border: 2px solid #1889b5;
  border-radius: 6px;
  transform: translateY(-3px);
}

@media only screen and (max-width: 767px) {
  .header-area .header-wrapper a.rn-btn {
    display: none;
  }
}

.header-area .header-wrapper .logo a img {
  max-width: 160px;
}

@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .logo a img {
    max-width: 80px;
  }
}

.header-area.color-white a.rn-btn {
  color: #fff;
  border: 2px solid #fff;
}

.header-area.color-black a.rn-btn {
  color: #1d1d24;
  border: 2px solid #1d1d2480;
}

.header-area.header--static {
  padding: 0 50px;
}

.header-area.header--static .header-wrapper {
  padding: 7px 0;
}

@media only screen and (max-width: 767px) {
  .header-area.header--static .header-wrapper .logo a img {
    width: auto;
  }
}

.header-area.header--fixed {
  z-index: 999;
  width: 100%;
  background: none;
  padding-left: 50px;
  padding-right: 50px;
  transition: all .3s;
  position: absolute;
  top: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-area.header--fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area.header--fixed {
    padding: 10px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .header-area.header--fixed {
    padding: 10px 30px;
  }
}

.header-area.header--fixed .header-wrapper {
  padding: 20px 0;
  transition: all .3s;
}

.header-area.header--fixed .header-wrapper .logo a img {
  transition: all .5s;
}

.header-area.header--fixed .header-wrapper .logo a img.logo-1 {
  display: block;
}

.header-area.header--fixed .header-wrapper .logo a img.logo-2 {
  display: none;
}

.header-area.header--fixed .header-wrapper .mainmenunav ul.mainmenu > li > a {
  position: relative;
}

.header-area.header--fixed .header-wrapper .mainmenunav ul.mainmenu > li > a:after {
  content: "";
  width: 0;
  height: 3px;
  background: #1889b5;
  transition: all .3s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.header-area.header--fixed .header-wrapper .mainmenunav ul.mainmenu > li.is-current > a {
  color: #1889b5;
}

.header-area.header--fixed .header-wrapper .mainmenunav ul.mainmenu > li.is-current > a:after {
  width: 100%;
}

.header-area.header--fixed.default-color.sticky .mainmenunav ul.mainmenu > li > a {
  color: #1f1f25;
}

.header-area.header--fixed.default-color.sticky .mainmenunav ul.mainmenu > li.is-current a {
  color: #1889b5;
}

.header-area.header--fixed.default-color.sticky .mainmenunav ul.mainmenu > li.is-current a:after {
  width: 100%;
}

.header-area.header--fixed.sticky {
  background: #fff;
  position: fixed;
  box-shadow: 0 0 10px #0000001a;
}

.header-area.header--fixed.sticky .header-wrapper {
  padding: 0;
}

.header-area.header--fixed.sticky .header-wrapper a.rn-btn {
  color: #1f1f25;
  border-color: #1f1f25;
}

.header-area.header--fixed.sticky .header-wrapper a.rn-btn:hover {
  color: #fff;
  border-color: #1889b5;
}

.header-area.header--fixed.sticky .logo a img {
  height: 50px;
}

.header-area.header--fixed.sticky .logo a img.logo-1 {
  display: none;
}

.header-area.header--fixed.sticky .logo a img.logo-2 {
  display: block;
}

.header-area.header--fixed.sticky .humberger-menu span.text-white, .header-area.color-black .humberger-menu span.text-white {
  color: #1f1f25 !important;
}

.header-area.logoresize .logo a img {
  height: 58px;
  width: auto !important;
}

@media only screen and (max-width: 575px) {
  .header-area.logoresize .logo a img {
    height: auto;
  }
}

.header-right {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.header-right .header-btn {
  margin-left: 50px;
}

@media only screen and (max-width: 575px) {
  .header-right .header-btn {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav {
    position: absolute;
  }
}

@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav {
    position: absolute;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu {
    width: 60vw;
    height: 100vh;
    z-index: 99;
    -o-transition: all .8s cubic-bezier(.77, .2, .05, 1);
    background: #fff;
    padding: 55px;
    transition: all .8s cubic-bezier(.77, .2, .05, 1);
    display: block;
    position: fixed;
    top: 0;
    right: -61vw;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu {
    width: 60vw;
    height: 100vh;
    z-index: 99;
    -o-transition: all .8s cubic-bezier(.77, .2, .05, 1);
    background: #fff;
    padding: 55px;
    transition: all .8s cubic-bezier(.77, .2, .05, 1);
    display: block;
    position: fixed;
    top: 0;
    right: -61vw;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li {
    margin: 0;
    padding: 10px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li {
    margin: 0;
    padding: 10px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown {
    position: relative;
  }
}

@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown, .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown > a {
    position: relative;
  }

  .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown > a:after {
    width: 0;
    height: 0;
    content: "";
    border: 7px solid #0000;
    border-top-color: #1f1f25;
    border-bottom-width: 0;
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown > a.open {
    color: #1889b5;
  }

  .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown > a.open:after {
    width: 0;
    height: 0;
    border: 7px solid #0000;
    border-top-width: 0;
    border-bottom-color: #1889b5;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown > a {
    position: relative;
  }

  .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown > a:after {
    width: 0;
    height: 0;
    content: "";
    border: 7px solid #0000;
    border-top-color: #1f1f25;
    border-bottom-width: 0;
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown > a.open {
    color: #1889b5;
  }

  .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown > a.open:after {
    width: 0;
    height: 0;
    border: 7px solid #0000;
    border-top-width: 0;
    border-bottom-color: #1889b5;
  }

  .header-area .header-wrapper .mainmenunav ul.mainmenu li a {
    padding: 0;
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li a {
    padding: 0;
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu {
    height: 0;
    opacity: 0;
    visibility: hidden;
    box-shadow: inherit;
    background: none;
    padding: 0;
    transition: all .3s;
    position: relative;
  }
}

@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu {
    height: 0;
    opacity: 0;
    visibility: hidden;
    box-shadow: inherit;
    background: none;
    padding: 0;
    transition: all .3s;
    position: relative;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu li {
    padding: 3px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu li {
    padding: 3px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu li a {
    padding: 2px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu li a {
    padding: 2px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu.active {
    height: 100%;
    opacity: 1;
    visibility: visible;
    padding: 12px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu.active {
    height: 100%;
    opacity: 1;
    visibility: visible;
    padding: 12px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li a {
    color: #1f1f25;
  }
}

@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li a {
    color: #1f1f25;
  }
}

.header-area .header-wrapper .close-menu {
  opacity: 0;
  visibility: hidden;
  z-index: -10;
  position: fixed;
  top: 12px;
  right: 29px;
}

.header-area .header-wrapper.menu-open .close-menu {
  opacity: 1;
  visibility: visible;
  z-index: 9999;
}

.header-area .header-wrapper.menu-open .close-menu span {
  color: #000;
  cursor: pointer;
  font-size: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper.menu-open .mainmenunav ul.mainmenu {
    right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header-area .header-wrapper.menu-open .mainmenunav ul.mainmenu {
    right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .header-area .header-wrapper.menu-open .mainmenunav ul.mainmenu {
    width: 86vw;
    padding: 54px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area.header-style-two .header-wrapper {
    padding: 30px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header-area.header-style-two .header-wrapper {
    padding: 15px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area.header-style-two .header-wrapper .logo a img {
    width: auto;
  }
}

@media only screen and (max-width: 767px) {
  .header-area.header-style-two .header-wrapper .logo a img {
    width: auto;
  }

  .header-area.header-style-two .header-wrapper ul.social-share li {
    margin: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-style-two .header-right .header-btn {
    margin-left: 7px;
  }
}

.humberger-menu span {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .humberger-menu span {
    font-size: 28px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .humberger-menu span {
    font-size: 28px;
  }
}

.header-wrapper.menu-open .humberger-menu span {
  opacity: 0;
}

.mainmenunav ul.mainmenu {
  flex-wrap: wrap;
  justify-content: flex-end;
  display: flex;
}

.mainmenunav ul.mainmenu > li {
  margin: 0 15px;
  position: relative;
}

.mainmenunav ul.mainmenu > li > a {
  color: #c6c9d8;
  padding: 20px 0;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
}

.mainmenunav ul.mainmenu > li > ul.submenu {
  min-width: 240px;
  height: auto;
  z-index: 90;
  opacity: 0;
  visibility: hidden;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  padding: 12px 0;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 0 35px #0000001a;
}

.mainmenunav ul.mainmenu > li > ul.submenu li {
  position: relative;
}

.mainmenunav ul.mainmenu > li > ul.submenu li a {
  color: #1f1f25;
  border-radius: 3px;
  margin: 0 10px;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 500;
  display: block;
}

.mainmenunav ul.mainmenu > li > ul.submenu li:hover > a {
  color: #1889b5;
  background: #f9004d12;
}

.mainmenunav ul.mainmenu > li > ul.submenu li .submenu {
  min-width: 240px;
  height: auto;
  z-index: 90;
  opacity: 0;
  visibility: hidden;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  padding: 12px 0;
  position: absolute;
  top: 0;
  left: 100%;
  box-shadow: 0 0 35px #0000001a;
}

.mainmenunav ul.mainmenu > li > ul.submenu li:hover .submenu, .mainmenunav ul.mainmenu > li:hover > ul.submenu {
  opacity: 1;
  visibility: visible;
}

.mainmenunav ul.mainmenu > li:hover > a {
  color: #1889b5;
}

.mainmenunav ul.mainmenu > li:first-child {
  margin-left: 0;
}

.mainmenunav ul.mainmenu > li:last-child {
  margin-right: 0;
}

.humberger-menu span.text-white {
  color: #c6c9d8 !important;
}

.color-white .mainmenunav ul.mainmenu > li > a {
  color: #fff;
}

.color-white .mainmenunav ul.mainmenu > li:hover > a {
  color: #fff9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .color-white .mainmenunav ul.mainmenu > li:hover > a {
    color: #1889b5;
  }
}

@media only screen and (max-width: 767px) {
  .color-white .mainmenunav ul.mainmenu > li:hover > a {
    color: #1889b5;
  }
}

.color-black .mainmenunav ul.mainmenu > li > a {
  color: #1d1d24;
}

.color-black .mainmenunav ul.mainmenu > li:hover > a {
  color: #1889b5;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-style-two .humberger-menu span.text-white {
    color: #1d1d24 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-style-two .humberger-menu span.text-white {
    color: #1d1d24 !important;
  }
}

@media only screen and (max-width: 767px) {
  .header-style-two .humberger-menu span.text-white {
    color: #1d1d24 !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1260px;
  }
}

.breadcrumb-inner {
  text-align: center;
}

.breadcrumb-inner h2.title {
  background: linear-gradient(145deg, #f81f01 0%, #ee076e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.breadcrumb-inner ul.page-list li {
  color: #fff;
  display: inline-block;
}

.breadcrumb-inner ul.page-list li a {
  color: #fff;
}

.breadcrumb-inner ul.page-list li.breadcrumb-item.active {
  color: #f10;
}

.breadcrupm-style--2 h2.title {
  text-transform: uppercase;
  font-size: 72px;
}

@media only screen and (max-width: 767px) {
  .breadcrupm-style--2 h2.title {
    font-size: 40px;
    line-height: 59px;
  }
}

.breadcrupm-style--2 span {
  color: #c6c9d8;
  font-size: 22px;
}

.rn-page-title h2.title {
  font-size: 72px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rn-page-title h2.title {
    font-size: 56px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-page-title h2.title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .rn-page-title h2.title {
    font-size: 40px;
    line-height: 59px;
  }
}

.rn-page-title p {
  color: #c6c9d8;
  font-size: 22px;
  line-height: 27px;
}

.backto-top > div {
  z-index: 999;
  width: 50px;
  height: 50px;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  line-height: 49px;
  overflow: hidden;
  box-shadow: 0 2px 2px #00000024, 0 1px 5px #0000001f, 0 3px 1px -2px #0003;
  z-index: 999 !important;
}

.backto-top svg {
  color: #222;
  width: 27px;
  height: 27px;
  font-size: 14px !important;
}

.section-title {
  margin-top: -20px;
}

.section-title h2.title {
  margin-bottom: 8px;
  font-size: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title h2.title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title h2.title {
    margin-bottom: 7px;
    font-size: 36px;
  }
}

.section-title p {
  color: #1d1d24bf;
  font-size: 18px;
  line-height: 30px;
}

.section-title p a {
  color: #1d1d24;
  font-weight: 500;
}

.section-title p a:hover {
  color: #1889b5;
}

@media only screen and (max-width: 767px) {
  .section-title p br {
    display: none;
  }
}

.section-title h3 {
  font-size: 36px;
  line-height: 26px;
}

@media only screen and (max-width: 767px) {
  .section-title h3 {
    margin-bottom: 8px;
    font-size: 26px;
  }
}

.section-title .service-btn {
  margin-top: 36px;
}

@media only screen and (max-width: 767px) {
  .section-title .service-btn {
    margin-top: 12px;
  }
}

.section-title.service-style--2 h2, .section-title.service-style--2 h3 {
  color: #fff;
}

.section-title.service-style--2 p {
  color: #fff;
  padding: 0 21%;
  font-weight: 300;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title.service-style--2 p {
    padding: 0 7%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title.service-style--2 p {
    padding: 0;
  }
}

.section-title.service-style--3 p {
  color: #717173;
  padding: 0 21%;
  font-weight: 300;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title.service-style--3 p {
    padding: 0 7%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title.service-style--3 p {
    padding: 0;
  }
}

.section-title.service-style--3.text-left p {
  padding: 0 3% 0 0;
}

.section-title-default h2.title {
  font-size: 48px;
  line-height: 1.34;
}

@media only screen and (max-width: 767px) {
  .section-title h2 {
    margin-bottom: 7px;
    font-size: 36px;
  }
}

a.btn-transparent {
  color: #1f1f25;
  padding-bottom: 3px;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  position: relative;
}

a.btn-transparent:hover {
  color: #1889b5;
}

a.btn-transparent:after {
  content: "";
  width: 40px;
  height: 2px;
  opacity: .75;
  background: #1889b5;
  position: absolute;
  bottom: 0;
  left: 0;
}

a.btn-transparent:hover:after {
  width: 100%;
}

a.rn-btn, button.rn-btn {
  height: 40px;
  z-index: 2;
  color: #1889b5;
  letter-spacing: .2px;
  text-transform: uppercase;
  border: 2px solid #1889b5;
  border-radius: 4px;
  padding: 0 23px;
  font-size: 14px;
  line-height: 34px;
  display: inline-block;
  position: relative;
}

a.rn-btn:hover, button.rn-btn:hover {
  color: #fff;
  background: #1889b5;
  border: 2px solid #1889b5;
  transform: translateY(-3px);
}

a.rn-btn.btn-white, button.rn-btn.btn-white {
  color: #fff;
  border: 2px solid #fff;
}

a.rn-btn.btn-white:hover, button.rn-btn.btn-white:hover {
  border-color: #1889b5;
}

button.rn-button-style--2, a.rn-button-style--2 {
  color: #c6c9d8;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: 2px solid #b1b4c1;
  border-radius: 6px;
  padding: 15px 40px;
  font-size: 16px;
  font-weight: 500;
  transition: all .3s;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  button.rn-button-style--2, a.rn-button-style--2 {
    padding: 9px 25px;
  }
}

button.rn-button-style--2.rn-btn-dark, a.rn-button-style--2.rn-btn-dark {
  color: #1f1f25;
  border: 2px solid #1f1f25;
}

button.rn-button-style--2.rn-btn-dark:hover, a.rn-button-style--2.rn-btn-dark:hover {
  color: #fff;
  background: #1f1f25;
}

button.rn-button-style--2.border-opacity-half, a.rn-button-style--2.border-opacity-half {
  border-color: #1f1f251a;
}

button.rn-button-style--2.rn-btn-small, a.rn-button-style--2.rn-btn-small {
  padding: 10px 20px;
}

button.rn-button-style--2.btn-primary-color:hover, a.rn-button-style--2.btn-primary-color:hover, button.rn-button-style--2.btn-solid, a.rn-button-style--2.btn-solid {
  color: #fff;
  background: #1889b5;
  border: 2px solid #1889b5;
}

button.rn-button-style--2.btn-solid, a.rn-button-style--2.btn-solid {
  color: #fff;
  background: #1889b5;
}

button.rn-button-style--2:hover, a.rn-button-style--2:hover {
  background: #106c91;
  transform: translateY(-5px);
  box-shadow: 0 10px 15px #0000001a;
}

button.video-popup {
  width: 96px;
  height: 96px;
  -o-transition: all 1s cubic-bezier(0, 0, .2, 1);
  background: #fff;
  border-radius: 50%;
  margin: 0 auto;
  transition: all 1s cubic-bezier(0, 0, .2, 1);
  display: block;
  position: relative;
  box-shadow: 0 20px 30px #0003;
}

button.video-popup:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 9px solid #0000;
  border-bottom: 9px solid #0000;
  border-left: 15px solid #1889b5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-6px, -9px);
}

button.video-popup:hover {
  transform: scale3d(1.15, 1.15, 1.15);
}

button.video-popup.position-top-center {
  width: 110px;
  height: 110px;
  border: 0;
  margin-top: -55px;
  margin-left: -55px;
  transition: all 1s cubic-bezier(0, 0, .2, 1);
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 20px 30px #0003;
}

@media only screen and (max-width: 767px) {
  button.video-popup.position-top-center {
    width: 70px;
    height: 70px;
    margin-top: -35px;
    margin-left: -35px;
  }
}

button.video-popup.position-top-center:hover {
  transform: scale3d(1.15, 1.15, 1.15);
}

button.video-popup.position-top-center.md-size {
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
}

button.video-popup.theme-color {
  background: #1889b5;
  border-color: #1889b5;
}

button.video-popup.theme-color:before {
  border-left: 15px solid #fff;
}

button.video-popup.black-color {
  background: #000;
  border-color: #000;
}

button.video-popup.black-color:before {
  border-left: 15px solid #fff;
}

.btn-confirm {
  color: #fff;
  background: #1889b5;
  border: 2px solid #1889b5;
  margin-right: 10px;
  font-weight: 600;
}

.btn-confirm:hover {
  color: #fff;
  opacity: .9;
  background: #1889b5;
  border: 2px solid #1889b5;
}

.btn-reject {
  color: #fff;
  background: #f4769a;
  border: 2px solid #f4769a;
  font-weight: 600;
}

.btn-reject:hover {
  color: #fff;
  opacity: .9;
  background: #f4769a;
  border: 2px solid #f4769a;
}

.rn-pagination ul.page-list li {
  padding: 5px;
  display: inline-block;
}

.rn-pagination ul.page-list li a {
  text-align: center;
  min-width: 42px;
  height: 42px;
  color: #000;
  z-index: 2;
  background: #fff;
  border: 2px solid #0000004d;
  border-radius: 4px;
  padding: 0 10px;
  font-weight: 600;
  line-height: 38px;
  display: block;
  position: relative;
}

.rn-pagination ul.page-list li a:before {
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  right: 0;
}

.rn-pagination ul.page-list li a:hover {
  color: #fff;
  border-color: #1889b5;
}

.rn-pagination ul.page-list li a:hover:before {
  opacity: 1;
  visibility: visible;
}

.rn-pagination ul.page-list li.active a {
  color: #fff;
  border-color: #1889b5;
}

.rn-pagination ul.page-list li.active a:before {
  opacity: 1;
  visibility: visible;
}

.line-separator {
  border-bottom: 1px solid #ebebeb;
}

ul.social-share {
  margin: -6px;
  padding: 0;
}

ul.social-share li {
  margin: 6px;
}

ul.social-share li a {
  width: 40px;
  height: 40px;
  color: #c6c9d8bf;
  text-align: center;
  border: 2px solid #c6c9d8bf;
  border-radius: 100%;
  font-size: 14px;
  line-height: 35px;
  display: inline-block;
}

ul.social-share li:hover a {
  color: #fff;
  background: #1889b5;
  border-color: #1889b5;
  transform: translateY(-3px);
  box-shadow: 0 10px 15px #0000001a;
}

ul.social-share.color-theme li a {
  color: #1889b5;
  border-color: #1889b5;
}

ul.social-share.color-theme li a:hover {
  color: #fff;
  background: #1889b5;
  border-color: #1889b5;
}

ul.social-share.rn-lg-size {
  margin-top: 0 !important;
}

ul.social-share.rn-lg-size li a {
  width: 50px;
  height: 50px;
  font-size: 16px;
  line-height: 44px;
}

ul.social-share.social-style--2 li a {
  width: 30px;
  height: auto;
  text-align: left;
  line-height: inherit;
  border: 0;
  font-size: 16px;
}

ul.social-share.social-style--2 li:hover a {
  box-shadow: none;
  background: none;
}

ul.social-share.social-style--2.color-theme li a {
  color: #1889b5;
}

ul.social-share.social-style--2.color-theme li a:hover {
  color: currentColor;
}

ul.social-share.social-style--2.color-black li a {
  color: #1d1d24;
  opacity: .8;
}

ul.social-share.social-style--2.color-black li a:hover {
  color: #1889b5;
  opacity: 1;
}

.footer-wrapper {
  position: relative;
}

.footer-left {
  z-index: 2;
  background: linear-gradient(145deg, #0ca2dd 0%, #1c49b3 100%);
  border-top-right-radius: 6px;
  margin-right: -15px;
  padding: 120px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-left {
    padding: 120px 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-left {
    padding: 60px 70px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-left {
    padding: 60px 30px;
  }
}

.footer-left:before {
  width: 100%;
  height: 100%;
  content: "";
  z-index: 1;
  opacity: .5;
  background-image: url("pattern.a469590f.webp");
  border-top-right-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
}

.footer-left .inner {
  z-index: 5;
  position: relative;
}

.footer-left .inner:after {
  z-index: -1;
  color: #0000001a;
  width: 295px;
  height: 301px;
  font-size: 240px;
  font-weight: 900;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.footer-left .inner > span {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 9px;
  font-size: 15px;
  display: block;
}

@media only screen and (max-width: 575px) {
  .footer-left .inner > span {
    letter-spacing: 1px;
    margin-bottom: 9px;
  }
}

.footer-left .inner h2 {
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 75px;
  font-weight: 900;
  line-height: 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-left .inner h2 {
    font-size: 50px;
    line-height: 61px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-left .inner h2 {
    font-size: 45px;
    line-height: 51px;
  }
}

.footer-left .inner a.rn-button-style--2 {
  color: #fff;
  border-color: #fff;
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .footer-left .inner a.rn-button-style--2 {
    margin-top: 10px;
    padding: 10px 23px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-left .inner a.rn-button-style--2 {
    padding: 7px 16px;
  }
}

.footer-left .inner a.rn-button-style--2:hover {
  color: #1889b5;
  background: #fff;
  border-color: #fff;
}

.footer-right {
  width: 100%;
  background-image: url("pattern-2.17c396fd.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 70px 150px 120px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-right {
    padding: 119px 21px 119px 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-right {
    padding: 60px 70px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-right {
    padding: 60px 30px;
  }
}

.footer-right .footer-link h4 {
  color: #c6c9d8;
  opacity: .75;
  margin-bottom: 30px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .footer-right .footer-link h4 {
    margin-bottom: 14px;
  }
}

.footer-right .footer-link ul.ft-link {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-right .footer-link ul.ft-link li a {
  color: #c6c9d8;
  opacity: .75;
  font-size: 16px;
}

.footer-right .footer-link ul.ft-link li:hover a {
  color: #1889b5;
}

.footer-right .footer-link ul.ft-link li + li {
  margin-top: 13px;
}

@media only screen and (max-width: 767px) {
  .footer-right .footer-link ul.ft-link li + li {
    margin-top: 8px;
  }
}

.footer-style-2 p {
  color: #c6c9d8;
  opacity: .75;
  font-size: 14px;
}

.copyright-text {
  position: absolute;
  bottom: -114px;
}

@media only screen and (max-width: 767px) {
  .copyright-text {
    padding-top: 33px;
    position: static;
    bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .copyright-text {
    padding-top: 33px;
    position: static;
    bottom: 0;
  }
}

.copyright-text p {
  color: #c6c9d8;
  opacity: .75;
  font-size: 14px;
}

.service.service__style--1 {
  z-index: 2;
  padding: 40px 0;
}

@media only screen and (max-width: 767px) {
  .service.service__style--1 {
    padding: 15px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service.service__style--1 {
    padding: 20px 0;
  }
}

.service.service__style--1 .icon img {
  margin-bottom: 34px;
}

@media only screen and (max-width: 767px) {
  .service.service__style--1 .icon img {
    height: 53px;
    margin-bottom: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .service.service__style--1 .icon img {
    height: 45px;
    margin-bottom: 13px;
  }
}

.service.service__style--1 .content h4.title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .service.service__style--1 .content h4.title {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 400;
  }
}

@media only screen and (max-width: 575px) {
  .service.service__style--1 .content h4.title {
    margin-bottom: 9px;
    font-size: 18px;
  }
}

.service.service__style--1 .content p {
  opacity: .75;
  font-weight: 300;
}

.service.service__style--2 {
  cursor: pointer;
  z-index: 2;
  border-radius: 10px;
  padding: 30px 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .service.service__style--2 {
    padding: 30px 28px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service.service__style--2 {
    padding: 30px 25px;
  }
}

@media only screen and (max-width: 767px) {
  .service.service__style--2 {
    padding: 30px 14px;
  }
}

@media only screen and (max-width: 575px) {
  .service.service__style--2 {
    margin-top: 30px;
    padding: 30px 18px;
  }
}

.service.service__style--2:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  background-image: linear-gradient(to right, #106c91, #57a1be);
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 575px) {
  .service.service__style--2:before {
    opacity: 1;
    visibility: visible;
  }
}

.service.service__style--2 .icon {
  color: #1889b5;
  margin-bottom: 10px;
  font-size: 54px;
  font-weight: 400;
  display: inline-flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service.service__style--2 .icon {
    margin-bottom: 11px;
  }
}

@media only screen and (max-width: 575px) {
  .service.service__style--2 .icon {
    color: #fff;
  }
}

.service.service__style--2 .icon svg {
  stroke-width: 1px !important;
}

.service.service__style--2 .content h3.title {
  margin-bottom: 19px;
  font-weight: 500;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .service.service__style--2 .content h3.title {
    font-size: 19px;
  }
}

@media only screen and (max-width: 767px) {
  .service.service__style--2 .content h3.title {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service.service__style--2 .content h3.title {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 575px) {
  .service.service__style--2 .content h3.title {
    color: #fff;
  }
}

.service.service__style--2 .content h3.title a {
  color: inherit;
}

@media only screen and (max-width: 575px) {
  .service.service__style--2 .content h3.title a {
    color: #fff;
  }
}

.service.service__style--2 .content p {
  color: #1d1d24bf;
}

@media only screen and (max-width: 575px) {
  .service.service__style--2 .content p {
    color: #fff;
  }
}

.service.service__style--2:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px 10px #0000001a;
}

.service.service__style--2:hover:before {
  opacity: 1;
  visibility: visible;
}

.service.service__style--2:hover .icon {
  color: #fff;
}

.service.service__style--2.service-left-align {
  padding: 50px 24px;
  display: flex;
}

.service.service__style--2.service-left-align .icon {
  padding-right: 20px;
}

.standard-service .thumbnai img {
  border-radius: 5px;
}

.standard-service .content h3 {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 19px;
  font-weight: 600;
}

.standard-service .content h3 a {
  color: #1f1f25;
}

.standard-service .content h3 a:hover {
  color: #1889b5;
}

.standard-service .content p {
  font-size: 15px;
  line-height: 24px;
}

.text-center .service.service__style--2, .text-left .service.service__style--2 {
  padding: 60px 45px;
}

@media only screen and (max-width: 767px) {
  .text-center .service.service__style--2, .text-left .service.service__style--2 {
    padding: 30px 14px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .text-center .service.service__style--2, .text-left .service.service__style--2 {
    padding: 30px 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .text-center .service.service__style--2, .text-left .service.service__style--2 {
    padding: 30px 14px;
  }
}

.service-white .service__style--1 {
  z-index: 2;
}

.service-white .service__style--1 .content h4.title, .service-white .service__style--1 .content p {
  color: #c6c9d8;
}

.rn-paralax-service .row {
  justify-content: center;
}

.rn-paralax-service .row .btn {
  margin-top: 20px;
}

.rn-paralax-service .service__style--2 {
  z-index: 2;
  height: 300px;
  background: #ffffff4d;
  border: 2px solid #0000;
  margin-top: 40px;
  padding: 40px;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .rn-paralax-service .service__style--2 {
    background: none;
    margin-top: 30px;
    padding: 30px 20px;
  }
}

.rn-paralax-service .service__style--2 .icon {
  color: #fff;
  align-items: center;
  display: flex;
}

.rn-paralax-service .service__style--2 h3.title {
  color: #fff;
  margin: 0 10px;
  font-size: 19px;
}

.rn-paralax-service .service__style--2 .content p {
  color: #ffffffbf;
}

.rn-paralax-service .service__style--2:hover {
  border: 2px solid #1889b5;
}

.rn-paralax-service .service__style--2:hover:before {
  border-radius: 0;
}

.creative-service-wrapper .row.creative-service a {
  height: 100%;
  display: flex;
}

.creative-service-wrapper .row.creative-service .service.service__style--2 {
  background: #f6f6f6;
  margin-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-one-wrapper {
    margin: 0 -40px;
  }
}

@media only screen and (max-width: 767px) {
  .bg_image--31 {
    background-position: 67% !important;
  }

  .bg_image--32 {
    background-position: 27% !important;
  }
}

.slider-fixed--height {
  min-height: 1080px;
  width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slider-fixed--height {
    min-height: 900px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-fixed--height {
    min-height: auto;
    padding: 60px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-fixed--height {
    min-height: auto;
    padding: 80px 0 60px;
  }
}

.slide .inner {
  padding-top: 100px;
}

@media only screen and (max-width: 767px) {
  .slide .inner {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 575px) {
  .slide .inner {
    padding-top: 30px;
  }
}

.slide .inner h1.title {
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 125px;
  font-weight: 900;
  line-height: 130px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slide .inner h1.title {
    font-size: 100px;
    line-height: 112px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide .inner h1.title {
    font-size: 90px;
    line-height: 103px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide .inner h1.title {
    margin-bottom: 0;
    font-size: 70px;
    line-height: 82px;
  }
}

@media only screen and (max-width: 767px) {
  .slide .inner h1.title {
    margin-bottom: 0;
    font-size: 50px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 575px) {
  .slide .inner h1.title {
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 50px;
  }
}

.slide.slide-style-1 .inner {
  padding-top: 100px;
}

@media only screen and (max-width: 767px) {
  .slide.slide-style-1 .inner {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 575px) {
  .slide.slide-style-1 .inner {
    padding-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.slide-style-1 .inner {
    padding-top: 50px;
  }

  .slide.slide-style-1 .inner h1.title {
    margin-bottom: 26px;
    font-size: 70px;
    line-height: 82px;
  }
}

.slide.slide-style-2 {
  padding-top: 230px;
  padding-bottom: 284px;
  overflow: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slide.slide-style-2 {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

.slide.slide-style-2.fullscreen {
  min-height: 950px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slide.slide-style-2.fullscreen {
    min-height: auto;
    padding-top: 160px;
    padding-bottom: 160px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide.slide-style-2.fullscreen {
    min-height: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.slide-style-2.fullscreen {
    min-height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .slide.slide-style-2.fullscreen {
    min-height: auto;
  }
}

.slide.slide-style-2.slider-box-content {
  height: 650px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide.slide-style-2.slider-box-content {
    height: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.slide-style-2.slider-box-content {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .slide.slide-style-2.slider-box-content {
    height: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide.slide-style-2 {
    min-height: auto;
    padding-top: 160px;
    padding-bottom: 170px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.slide-style-2 {
    min-height: auto;
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .slide.slide-style-2 {
    min-height: auto;
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

.slide.slide-style-2 .inner {
  z-index: 2;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .slide.slide-style-2 .inner {
    padding-top: 0;
    text-align: center !important;
  }

  .slide.slide-style-2 .inner.text-right {
    text-align: right !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.slide-style-2 .inner {
    padding-top: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide.slide-style-2 .inner {
    padding-top: 30px;
  }
}

.slide.slide-style-2 .inner > span {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 9px;
  font-size: 15px;
  display: block;
}

.slide.slide-style-2 .inner h1.title {
  color: #fff;
  font-size: 100px;
  font-weight: 900;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide.slide-style-2 .inner h1.title {
    font-size: 72px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.slide-style-2 .inner h1.title {
    margin-bottom: 18px;
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .slide.slide-style-2 .inner h1.title {
    font-size: 36px;
    line-height: 64px;
  }
}

.slide.slide-style-2 .inner p.description {
  color: #fff;
  margin: 0;
  padding: 0 16%;
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
}

@media only screen and (max-width: 767px) {
  .slide.slide-style-2 .inner p.description {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.slide-style-2 .inner p.description {
    padding: 0 10%;
  }
}

.slide.slide-style-2 .inner .slide-btn {
  margin-top: 50px;
}

.slide.slide-style-2 .inner.text-left p {
  padding: 0 37% 0 0;
}

@media only screen and (max-width: 767px) {
  .slide.slide-style-2 .inner.text-left p {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.slide-style-2 .inner.text-left p {
    padding: 0 3% 0 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide.slide-style-2 .inner.text-left p {
    padding: 0 20% 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .slide.slide-style-2 .inner.text-left p {
    padding: 0 0 0%;
  }
}

.slide.slide-style-2 .inner.text-right p {
  padding: 0 0 0% 46%;
}

@media only screen and (max-width: 767px) {
  .slide.slide-style-2 .inner.text-right p {
    padding: 0 0 0%;
  }
}

.slide.slide-style-2.without-overlay .inner p.description {
  color: #fff;
  opacity: .7;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slide.slide-style-2.slider-paralax {
    height: auto;
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

.slide.slider-style-3 .inner > span {
  color: #1f1f25;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 20px;
  font-size: 15px;
  display: block;
}

@media only screen and (max-width: 767px) {
  .slide.slider-style-3 .inner > span {
    margin-bottom: 10px;
    font-size: 13px;
  }
}

.slide.slider-style-3 .inner h1.title {
  color: #1f1f25;
  text-transform: inherit;
  width: 70%;
  font-family: Montserrat, sans-serif;
  font-size: 75px;
  font-weight: 900;
  line-height: 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide.slider-style-3 .inner h1.title {
    font-size: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.slider-style-3 .inner h1.title {
    width: 85%;
    font-size: 60px;
    line-height: 77px;
  }
}

@media only screen and (max-width: 767px) {
  .slide.slider-style-3 .inner h1.title {
    width: 100%;
    font-size: 42px;
    line-height: 57px;
  }
}

.slide.slider-style-3 .inner h1.title span {
  color: #1889b5;
}

.slider-wrapper.color-white .inner p.description {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .slider-wrapper .service-wrapper {
    margin-bottom: -20px;
  }
}

.react-parallax img {
  object-fit: cover;
  height: auto !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .react-parallax img {
    height: 1232px !important;
  }
}

@media only screen and (max-width: 767px) {
  .react-parallax img {
    height: 1232px !important;
  }
}

.personal-portfolio-slider {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .personal-portfolio-slider:before {
    content: "";
    width: 100%;
    height: 100%;
    opacity: .7;
    background: #fff;
    position: absolute;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .personal-portfolio-slider:before {
    content: "";
    width: 100%;
    height: 100%;
    opacity: .7;
    background: #fff;
    position: absolute;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .personal-portfolio-slider:before {
    content: "";
    width: 100%;
    height: 100%;
    opacity: .7;
    background: #fff;
    position: absolute;
  }
}

@media only screen and (max-width: 767px) {
  .slide.personal-portfolio-slider.slider-style-3, .slide.designer-portfolio.slider-style-3 {
    padding: 100px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.personal-portfolio-slider.slider-style-3, .slide.designer-portfolio.slider-style-3 {
    padding: 150px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide.personal-portfolio-slider.slider-style-3, .slide.designer-portfolio.slider-style-3 {
    min-width: auto;
    height: auto;
    padding: 150px 0;
  }
}

@media only screen and (max-width: 767px) {
  .slide.personal-portfolio-slider.slider-style-3 .inner, .slide.designer-portfolio.slider-style-3 .inner {
    padding-top: 56px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.personal-portfolio-slider.slider-style-3 .inner, .slide.designer-portfolio.slider-style-3 .inner {
    padding-top: 76px;
  }
}

@media only screen and (max-width: 767px) {
  .slide.personal-portfolio-slider.slider-style-3 .inner h1.title, .slide.designer-portfolio.slider-style-3 .inner h1.title {
    font-size: 32px;
    line-height: 49px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.personal-portfolio-slider.slider-style-3 .inner h1.title, .slide.designer-portfolio.slider-style-3 .inner h1.title {
    font-size: 53px;
    line-height: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide.personal-portfolio-slider.slider-style-3 .inner h1.title, .slide.designer-portfolio.slider-style-3 .inner h1.title {
    font-size: 58px;
    line-height: 75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slide.personal-portfolio-slider.slider-style-3 .inner h1.title, .slide.designer-portfolio.slider-style-3 .inner h1.title {
    font-size: 54px;
    line-height: 68px;
  }

  .slide.personal-portfolio-slider.slider-style-3 .inner h2, .slide.designer-portfolio.slider-style-3 .inner h2 {
    font-size: 54px;
    line-height: 1.3;
  }
}

@media only screen and (max-width: 767px) {
  .slide.personal-portfolio-slider.slider-style-3 .inner h2, .slide.designer-portfolio.slider-style-3 .inner h2 {
    font-size: 32px;
    line-height: 1.3;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.personal-portfolio-slider.slider-style-3 .inner h2, .slide.designer-portfolio.slider-style-3 .inner h2 {
    font-size: 53px;
    line-height: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide.personal-portfolio-slider.slider-style-3 .inner h2, .slide.designer-portfolio.slider-style-3 .inner h2 {
    font-size: 58px;
    line-height: 75px;
  }
}

.slide.designer-portfolio {
  height: 700px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.designer-portfolio {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .slide.designer-portfolio {
    height: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.designer-portfolio.slider-style-3 {
    height: auto;
    padding: 100px 0;
  }
}

@media only screen and (max-width: 767px) {
  .slide.designer-portfolio.slider-style-3 {
    height: auto;
    padding: 100px 0;
  }
}

.slide.designer-portfolio.slider-style-3 .inner {
  padding: 0;
}

.slide.designer-portfolio.slider-style-3 .inner h1.title {
  width: 100%;
}

.slide.designer-portfolio.slider-style-3 .designer-thumbnail img {
  width: 394px;
  height: 394px;
  border: 14px solid #fff;
  border-radius: 100%;
  padding: 0;
  box-shadow: 0 20px 40px #0000001a, 0 20px 40px #ffffff26;
}

@media only screen and (max-width: 575px) {
  .slide.designer-portfolio.slider-style-3 .designer-thumbnail img {
    width: auto;
    height: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide.designer-portfolio.slider-style-3 .designer-thumbnail img {
    width: auto;
    height: auto;
  }
}

.slider-digital-agency a.rn-button-style--2 {
  color: #fff;
  border-color: #fff;
}

.slider-video-bg {
  z-index: 2;
  position: relative;
}

.slider-video-bg .video-background {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.slider-video-bg .inner {
  z-index: 2;
  position: relative;
}

.slider-video-bg[data-black-overlay]:before {
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-video-bg .video-inner button.video-popup {
    margin: 28px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .slider-video-bg .video-inner button.video-popup {
    width: 70px;
    height: 70px;
    margin: 30px auto 0;
  }
}

.slide.slide-style-2.slider-video-bg .inner .title {
  margin-bottom: 20px;
  font-size: 60px;
  line-height: 80px;
}

@media only screen and (max-width: 767px) {
  .slide.slide-style-2.slider-video-bg .inner .title {
    margin-bottom: 13px;
    font-size: 44px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .slide.slide-style-2.slider-video-bg .inner .title {
    font-size: 36px;
    line-height: 53px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-creative-agency .slider-paralax {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .slider-creative-agency .slider-paralax {
    height: auto;
  }
}

.slider-creative-agency.with-particles {
  position: relative;
}

.slider-creative-agency.with-particles .frame-layout__particles {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}

.slider-creative-agency.with-particles .frame-layout__particles canvas {
  z-index: 1;
  position: absolute;
}

.slider-creative-agency.with-particles .inner {
  z-index: 4;
  position: relative;
}

.slide .service {
  margin-top: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide .service {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slide .service {
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide .service {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .slide .service {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .slide .service {
    margin-top: 0;
  }
}

.slider-paralax, .rn-paralax {
  height: 950px;
}

@media only screen and (max-width: 767px) {
  .slider-paralax, .rn-paralax {
    height: auto;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slider-paralax, .rn-paralax {
    height: auto;
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-paralax, .rn-paralax {
    height: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-paralax, .rn-paralax {
    height: auto;
  }
}

.slick-slide.slick-current .slide.slide-style-2 .inner h1.title {
  animation: .8s ease-in-out customOne;
}

.slick-slide.slick-current .slide.slide-style-2 .inner p.description {
  animation: 1s ease-in-out customOne;
}

.slick-slide.slick-current .slide.slide-style-2 .inner .slide-btn {
  animation: 1.2s ease-in-out customOne;
}

@media only screen and (max-width: 767px) {
  .bg_image--16 {
    background-position: 71%;
  }
}

.slider-activation {
  position: relative;
}

.slider-activation .slick-dots {
  bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .slider-activation .slick-dots {
    bottom: 88px;
  }
}

.slider-activation button.slick-arrow {
  z-index: 2;
  opacity: 1;
  width: 68px;
  height: 68px;
  border: 2px solid #fff3;
  border-radius: 100%;
  transition: all .5s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-activation button.slick-arrow {
    display: none !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-activation button.slick-arrow {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .slider-activation button.slick-arrow {
    display: none !important;
  }
}

.slider-activation button.slick-arrow:hover {
  background: #1889b5;
  border-color: #1889b5;
}

.slider-activation button.slick-arrow:before {
  opacity: .4;
  z-index: 2;
  line-height: 1;
  transition: all .5s;
  position: relative;
  top: -2px;
}

.slider-activation button.slick-arrow.slick-prev {
  left: 50px;
}

@media only screen and (max-width: 767px) {
  .slider-activation button.slick-arrow.slick-prev {
    left: 10px;
  }
}

.slider-activation button.slick-arrow.slick-prev:hover {
  left: 30px;
}

@media only screen and (max-width: 767px) {
  .slider-activation button.slick-arrow.slick-prev:hover {
    left: 20px;
  }
}

.slider-activation button.slick-arrow.slick-prev:hover:before {
  opacity: 1;
}

.slider-activation button.slick-arrow.slick-next {
  left: auto;
  right: 50px;
}

@media only screen and (max-width: 767px) {
  .slider-activation button.slick-arrow.slick-next {
    right: 10px;
  }
}

.slider-activation button.slick-arrow.slick-next:hover {
  right: 30px;
}

@media only screen and (max-width: 767px) {
  .slider-activation button.slick-arrow.slick-next:hover {
    right: 20px;
  }
}

.slider-activation button.slick-arrow.slick-next:hover:before {
  opacity: 1;
}

@keyframes customOne {
  from {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.customOne {
  animation-name: customOne;
}

.theme-gradient {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.thumbnail img {
  border-radius: 5px;
  box-shadow: 0 25px 65px #0000001a;
}

.box-model .service-wrapper {
  border-radius: 6px;
  padding: 10px 50px;
  position: relative;
}

.box-model .service-wrapper .service {
  margin: 0;
}

.box-model .service-wrapper .service .icon {
  text-align: center;
  margin: auto;
}

.box-model .service-wrapper .service .content {
  text-align: center;
}

.box-model .service-wrapper:before {
  width: 90%;
  height: 80%;
  content: "";
  z-index: -1;
  filter: blur(30px);
  background-color: #f81f01;
  background-image: linear-gradient(145deg, #f81f01 0%, #ee076e 100%);
  border-radius: 20%;
  margin: auto;
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
}

.box-model .inner {
  text-align: center;
  margin-bottom: 60px;
}

.error-page-inner {
  height: calc(100vh - 120px);
  min-height: 700px;
  align-items: center;
  display: flex;
}

.error-page-inner .inner {
  text-align: center;
}

.error-page-inner .inner h1.title {
  margin-bottom: 0;
  font-size: 180px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .error-page-inner .inner h1.title {
    font-size: 107px;
  }
}

.error-page-inner .inner .sub-title {
  color: #fff;
  margin-bottom: 0;
  font-size: 48px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .error-page-inner .inner .sub-title {
    font-size: 40px;
  }
}

.error-page-inner .inner span {
  color: #c6c9d8;
  opacity: .75;
  margin-top: 10px;
  margin-bottom: 28px;
  font-size: 16px;
  font-weight: 300;
  display: block;
}

.counterup_style--1 {
  text-align: center;
  margin-top: 40px;
}

.counterup_style--1 h5.counter {
  color: #1889b5;
  margin-bottom: 17px;
  margin-left: -20px;
  font-size: 72px;
  line-height: 80px;
  display: inline-block;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .counterup_style--1 h5.counter {
    margin-bottom: 5px;
    margin-left: -11px;
    font-size: 45px;
    line-height: 56px;
  }
}

.counterup_style--1 h5.counter:after {
  content: "+";
  font-size: 40px;
  position: absolute;
  top: -17px;
  right: -26px;
}

.counterup_style--1 p.description {
  color: #1d1d24bf;
  padding: 0 5%;
  font-size: 18px;
  line-height: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counterup_style--1 p.description {
    padding: 0;
    font-size: 16px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .counterup_style--1 p.description {
    padding: 0;
    font-size: 16px;
    line-height: 28px;
  }
}

.rn-paralax-counterup .counterup_style--1 {
  text-align: center;
}

.rn-paralax-counterup .counterup_style--1 h5.counter {
  color: #1889b5;
}

.rn-paralax-counterup .counterup_style--1 p.description {
  color: #fff;
}

.counterup_style--2 {
  text-align: center;
}

.counterup_style--2 h5.counter {
  color: #1889b5;
  margin-bottom: 10px;
  font-size: 50px;
  line-height: 60px;
  display: inline-block;
}

.counterup_style--2 h4.description {
  margin-bottom: 0;
  padding: 0 10%;
  font-size: 16px;
  line-height: 30px;
}

.theme-text-white .counterup_style--1 h5.counter, .theme-text-white .counterup_style--1 p.description {
  color: #fff;
}

.lds-heart {
  width: 80px;
  height: 80px;
  transform-origin: 40px 40px;
  display: inline-block;
  position: relative;
  transform: rotate(45deg);
}

.lds-heart div {
  width: 32px;
  height: 32px;
  background: #fff;
  animation: 1.2s cubic-bezier(.215, .61, .355, 1) infinite lds-heart;
  position: absolute;
  top: 32px;
  left: 32px;
}

.lds-heart div:after, .lds-heart div:before {
  content: " ";
  width: 32px;
  height: 32px;
  background: #fff;
  display: block;
  position: absolute;
}

.lds-heart div:before {
  border-radius: 50% 0 0 50%;
  left: -24px;
}

.lds-heart div:after {
  border-radius: 50% 50% 0 0;
  top: -24px;
}

@keyframes lds-heart {
  0% {
    transform: scale(.95);
  }

  5% {
    transform: scale(1.1);
  }

  39% {
    transform: scale(.85);
  }

  45% {
    transform: scale(1);
  }

  60% {
    transform: scale(.95);
  }

  100% {
    transform: scale(.9);
  }
}

.loader {
  height: 100vh;
  width: 100%;
  background: linear-gradient(#1d9bd6 40%, #fff);
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

/*# sourceMappingURL=index.184ccd3a.css.map */
